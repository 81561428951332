import { Component } from '@angular/core';
import { DataServiceService } from '../helpers/data.service';
import { ConfigService } from '../helpers/config.service';

@Component({
  selector: 'app-yandex',
  templateUrl: './yandex.component.html',
  styleUrl: './yandex.component.scss'
})
export class YandexComponent {
  csData:any = {};
  showCsData:boolean = true;
  constructor(public data:DataServiceService, public cs: ConfigService){
    this.data.setIsCouponLastStep(false);
    this.data.setHeaderMenuSelection('6');
  }

  async ngOnInit(): Promise<void> {
    this.csData = {};
    this.showCsData = true;
    await this.cs.getConfigurations('yandex', 'en');
    this.data.setCsObj(this.cs);
    this.csData = this.cs;
    setTimeout(() => {
      this.showCsData = false;
  }, 100);
  }
}
