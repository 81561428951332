<div class="header">
    <div class="flex h-full">
        <div class="col h-full text-left">
            <div class="flex" style="height: 54px;">
                <div class="logo h-full" (click)="tabSelection('11')">
                    <div class="logo-box inline-block"></div>
                    <div class="logo-text inline-block">
                        <label class="text1"><b>COUPONER</b></label>
                        <label class="text2">by</label>
                        <i></i>
                    </div>
                </div>
                <div class="coupon-menu col h-full">
                    <!--<p-menu #menu [model]="headerMenuItems" [popup]="true" (onShow)="onShowCouponMenu($event)" (onHide)="onHideCouponMenu($event)"></p-menu>-->
                    <!--<p-button type="button" (click)="menu.toggle($event)" icon="pi pi-bars" [label]="menuSelection? menuSelection.label : ''" [styleClass]="isCouponMenuOpen ? 'coupon-menu-btn menu-open' : 'coupon-menu-btn'"></p-button>-->
                    <p-dropdown [styleClass]="isDropdownOpen ? 'opend' : ''" panelStyleClass="coupon-menu-panel-style" [options]="headerMenuItems" [(ngModel)]="menuSelection" optionLabel="label" [showClear]="true" (onShow)="isDropdownOpen = true" (onHide)="isDropdownOpen = false" (onChange)="changeCouponSelection($event)">
                        <ng-template pTemplate="selectedItem">
                            <span *ngIf="menuSelection">{{menuSelection.label}}<i class="arrow"></i></span>
                        </ng-template>
                        <ng-template let-headerItem pTemplate="item">
                            <div [ngClass]="headerItem.styleClass">
                                <i></i>
                                <div>{{headerItem.label}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>

                </div>
            </div>
        </div>
        <div class="col h-full text-right">
            <div class="links">
                <span *ngFor="let item of tabList"><p-button (click)="tabSelection(item.id)" [label]="item.text" [attr.selected]="isSelectedTabOrCoupon(item)"><div class="selected-line"></div></p-button></span>
            </div>
            <div class="mobile-menu">
                <button pButton type="button" (click)="openMenu()" icon="pi menu-black" label="Show"></button>
            </div>
        </div>
    </div>
</div>

<p-dialog [(visible)]="showDialog" styleClass="header-mobile-menu-dialog" [modal]="false" [blockScroll]="false">
    <ng-template pTemplate="header"></ng-template>
    <ul>
        <li *ngFor="let item of headerMobileMenuItems">
            <p-button *ngIf="item" (click)="changeMobileMenuSelection(item)" [attr.selected]="isSelectedTabOrCoupon(item)" [icon]="item.icon" [label]="item.label">
                <div class="selected-line"></div>
            </p-button>

        </li>
    </ul>

  </p-dialog>


