import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn} from '@angular/router';
import { Observable, of } from 'rxjs';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
class GuardService  {
  constructor(private router: Router, private navigationService: NavigationService) { }
  canActivateChannel(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const targetStep = route.url[1].path;
    const targetLan = route.url[0].path;
    if (targetStep.includes('step2')) {
      const [listItemName, infoItemName, tokenItemName] = this.getItemNameByTarget(targetLan);
      const info = infoItemName? sessionStorage.getItem(infoItemName): null;
      const sessionToken = tokenItemName? sessionStorage.getItem(tokenItemName): null;
      const passedList = listItemName? sessionStorage.getItem(listItemName): null;
      if (info && sessionToken && passedList) {
        const infoJson = JSON.parse(info);
        const refreshToken = infoJson.refresh_token;
        const managerLinks = JSON.parse(passedList);
        if (refreshToken && managerLinks && managerLinks.length) {
          return of(true);
        }
      }
    } else {
      if (targetStep.includes('step3') && this.navigationService.getNavigateFlagByPage(targetLan)){
        return of(true);
      }
    }
    this.router.navigate([`/${targetLan}`]);
    return of(false);
  }

  getItemNameByTarget(targetLan: string | null): [string | null, string | null, string | null] {
    switch (targetLan) {
      case 'g':
      case 'g-cn':
        return ['googlePassedList', 'googleInfo', 'googleToken'];
      case 'ms':
      case 'ms-cn':
        return ['microsoftPassedList', 'microsoftInfo', 'microsoftToken'];
      case 'n':
        return ['netflixPassedList', 'netflixInfo', 'netflixToken'];
      case 'yt':
        return ['youtubePassedList', 'youtubeInfo', 'youtubeToken'];
      default:
        return [null, null, null];
    }
  }
}

export const canActivateChannel: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(GuardService).canActivateChannel(next);
};
