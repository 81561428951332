<!--<div class="coupon-layout microsoft-coupon last-step">
    <div class="show-coupon">
        <div class="microsoft-image">
            <div class="image cn">
                <div class="shadow"></div>
                <div class="on-top-shadow">
                    <div class="adcore-logo white"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
                    <div class="microsoft-coupon-image"><div class="confetti-cover" *ngIf="showConfetti"></div></div>
                    <div class="content bold">
                      <div *ngIf="action === 'link'">
                        <span>您的优惠券申请已提交！</span>
                      </div>
                      <div *ngIf="action === 'create'">
                        <span>账号创建成功！</span>
                      </div>
                    </div>
                    <div class="video-mobile-view">
                        <app-ad-video [showVideo]="true" title="我在哪里查看我的优惠券？" [isMobile]="true" videoName="microsoft-video"></app-ad-video>
                    </div>
                    <div class="microsoft-ad-text">
                      <div *ngIf="action === 'link'">
                        如果您的账户符合条件，发送优惠券可能需要最多35天的时间。
                        有关账户符合条件和促销代码政策的更多信息，请参阅Microsoft Ads的
                        <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank">条款和条件</a><button class="qBtn" (click)="goToQA()">以及优惠券常见问题解答</button>。
                      </div>
                      <div *ngIf="action === 'create'">
                        感谢您通过Couponer创建账号。 如果您的账号符合条件，您将收到电子邮件通知。发送优惠券可能需要最多35天的时间。
                        有关账户符合条件和促销代码政策的更多信息，请参阅Microsoft Ads的
                        <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank">条款和条件</a><button class="qBtn" (click)="goToQA()">以及优惠券常见问题解答</button>。
                      </div>
                    </div>
                    <div class="buttons">
                        <div class="get-coupon-btn"><a href="https://couponer.app/g-cn" target="_blank"><span class="icon-box"><i></i></span><span class="text">获取500美元的Google广告优惠券</span></a></div>
                        <span class="or desktop">或者</span>
                        <div class="another-acc-btn"><p-button styleClass="link-btn" label="链接另一个Microsoft账户" (onClick)="linkAccount()"></p-button></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="video-desk-view">
            <app-ad-video [showVideo]="showVideo" title="我在哪里查看我的优惠券？" [isMobile]="false" videoName="microsoft-video"></app-ad-video>
        </div>
        <div class="apps-main-title bold">试用我们的其他广告科技工具</div>
        <div class="apps">
            <app-items-app [lang]="'cn'" src="msft"></app-items-app>
        </div>
    </div>
    <app-questions [listItemsType]="'cnMicrosoft'" [title]="'常见问题'" [isLastStep]="true"></app-questions>
    <app-footer [lang]="'cn'"></app-footer>
</div> -->

<div class="coupon-layout fixed-header">
  <app-header></app-header>
  <div class="main-content">
      <div>
          <div>
            <div class="coupon-layout google-coupon">
              <div class="show-coupon">
                <div><i class="mc-palette main-logo"></i></div>
                <div class="content">
                  <div *ngIf="action === 'link'">
                    <span>您的优惠券申请已提交！</span>
                  </div>
                  <div *ngIf="action === 'create'">
                    <span>账号创建成功！</span>
                  </div>
                </div>
                <div class="google-ad-text">
                  <div *ngIf="action === 'link'">
                    如果您的账户符合条件，发送优惠券可能需要最多35天的时间。
                    有关账户符合条件和促销代码政策的更多信息，请参阅Microsoft Ads的
                    <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank">条款和条件</a><!--<button class="qBtn" (click)="goToQA()">以及优惠券常见问题解答</button>-->。
                  </div>
                  <div *ngIf="action === 'create'">
                    感谢您通过Couponer创建账号。 如果您的账号符合条件，您将收到电子邮件通知。发送优惠券可能需要最多35天的时间。
                    有关账户符合条件和促销代码政策的更多信息，请参阅Microsoft Ads的
                    <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank">条款和条件</a><!--<button class="qBtn" (click)="goToQA()">以及优惠券常见问题解答</button>-->。
                  </div>
                </div>
                <div class="buttons">
                  <div class="get-coupon-btn"><a href="https://couponer.app/g-cn" target="_blank"><span class="icon-box"><i></i></span><span class="text">获取500美元的Google广告优惠券</span></a></div>
                        <span class="or desktop">或者</span>
                        <div class="another-acc-btn"><p-button styleClass="link-btn" label="链接另一个Microsoft账户" (onClick)="linkAccount()"></p-button></div>
              </div>
              </div>
            </div>
            <div class="popular-coupon">
              <h3>Explore popular coupons</h3>
              <div class="coupon-box-large-layout">
                <app-coupon-box [listItem]="this.data.popularCouponItem" [isSecondaryLayout]="true" (redeemEvent)="this.data.goToCoupon('3')"></app-coupon-box>
              </div>
            </div>
            <h3 class="explore-title">Explore all coupons</h3>
              <div class="coupon-layout-home-body">
                  <app-all-coupons></app-all-coupons>
              </div>
          </div>
      </div>
      <app-footer [lang]="'en'"></app-footer>
  </div>
</div>


