<div class="msg-template" *ngIf="message">
    <div *ngIf="message.summary"><h4>{{message.summary}}</h4></div>
    <div class="icon-align"><i class="msg-icon" [ngClass]="message.severity"></i></div>
    <ng-content></ng-content>
    <div class="btn-box">
        <button type="button" pButton label="got it" class="p-button-success" (click)="onConfirm()"></button>
    </div>
</div>



