import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-video',
  templateUrl: './ad-video.component.html',
  styleUrls: ['./ad-video.component.scss']
})
export class AdVideoComponent implements OnInit {

  @Input() showVideo: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() title: string = '';
  @Input() videoName:string = ''
  isResize:boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  resize() {
    this.isResize = !this.isResize;
  }


}
