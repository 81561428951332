import {Component, OnDestroy, OnInit} from '@angular/core';
import {SocialAuthService, SocialUser} from "@abacritt/angularx-social-login";
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import googleData from '../googleCodes.json'
import bingData from '../msCodes.json'
import {lastValueFrom, Subject} from "rxjs";
import {WindowRefService} from "../../../helpers/windowref.service";
import moment from 'moment-timezone';
import {ApiCallService} from "../../../helpers/api-call.service";
import {Timezone} from "../../../helpers/models";

@Component({
  selector: 'app-create-google-ads-account',
  templateUrl: './create-google-ads-account.component.html',
  styleUrls: ['./create-google-ads-account.component.scss']
})

export class CreateGoogleAdsAccountComponent implements OnInit, OnDestroy {
  componentDestroyed: Subject<boolean> = new Subject();
  user: SocialUser | undefined;
  channel: string = 'google';
  page: string | undefined;
  currencies: object[] = [];
  timezones: object[] = [];
  accountName: string | null = null;
  selectedCurrency: string | null = null;
  selectedTimezone: string | null = null;
  loading: boolean = false;
  score: number = 0;
  brandNames = '';
  website = '';
  businessType: string | null = null;
  eu: string | null = null;
  businessTypes = [
    {label: 'E-commerce', value: 'e-commerce'},
    {label: 'Other', value: 'other'}
  ];
  eus = [
    {label: 'EU countries', value: 'eu'},
    {label: 'Non-EU countries', value: 'non-eu'}
  ];
  websiteError: string | null = null;
  constructor(private authService: SocialAuthService, public ref: DynamicDialogRef,
              private apiCallService: ApiCallService,
              public dialogConfig: DynamicDialogConfig, private winRef: WindowRefService) { }

  ngOnInit(): void {
    this.user = this.dialogConfig?.data.user;
    this.channel = this.dialogConfig?.data.channel;
    this.score = this.dialogConfig?.data.recaptchaScore;
    if (this.dialogConfig?.data.page) {
      this.page = this.dialogConfig?.data.page;
    } else {
      this.page = this.channel;
    }
    const currencyCodes = this.channel === 'google' ? googleData.currencyCodes: bingData.currencyCodes;
    this.currencies = Array.from(currencyCodes, x => ({label: x, value: x}));
    this.selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const gmtValue = moment().tz(this.selectedTimezone).format('ZZ');
    if (this.channel === 'google') {
      this.timezones = Array.from(googleData.timezones, x => ({label: x + ' GMT' + moment().tz(x).format('ZZ'), value: x}));
    } else {
      this.timezones = Array.from(bingData.timezones) ;
      console.log(gmtValue);
      const formatedTimezones = this.timezones as Timezone[];
      const matchingTimezones = formatedTimezones.filter(item => item.label.includes(gmtValue));
      const geoInfo = this.getGeoInfo();
      const countrySpecificMatch = matchingTimezones.find(item =>
        geoInfo.some(geo => item.label.includes(geo))
      );
      this.selectedTimezone = countrySpecificMatch
        ? countrySpecificMatch.value
        : matchingTimezones.length > 0
          ? matchingTimezones[0].value
          : null;
    }
  }

  private getGeoInfo(): any[] {
    const nativeGeoIp = this.winRef.nativeGeoIp;
    let geoInfo: any[] = [];
    if (nativeGeoIp) {
      nativeGeoIp.city(
        (geoipResponse: any) => {geoInfo = [geoipResponse.country.iso_code, geoipResponse.country.names.en,
          geoipResponse.city.names.en, geoipResponse.subdivisions.names.en];},
        (err: any) => {console.log('nativeGeoIp Error', err);});
    }
    return geoInfo;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  get channelMsg(): string {
    return this.channel === 'google'? 'Google': 'Microsoft';
  }

  async createAccount(): Promise<void> {
    // For UI testing purposes
    // this.loading = true;
    // const response = {'result': 'created', 'customerId': '1234567890'}
    // const returnData = {response: response, user: this.user};
    // sessionStorage.setItem(<string>this.user?.email, JSON.stringify(response));
    // this.loading = false;
    // this.ref.close(returnData);

    // For production
    this.websiteError = null;
    if (this.website && this.website.length) {
      const re2 = new RegExp('^(http(s)?://)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-._~:/?#[\\]@!$&\'()*+,;=]+$');
      if (!re2.test(this.website || '')) {
        this.websiteError = 'Please enter a valid website address';
      } else {
        this.loading = true;
        const params = {
          user: this.user,
          accountName: this.accountName,
          currency: this.selectedCurrency,
          timezone: this.selectedTimezone,
          geo: this.dialogConfig?.data.geo,
          sessionToken: sessionStorage.getItem(this.page + 'Token'),
          recaptchaScore: this.score,
          brandNames: this.brandNames,
          hasShoppingAds: this.businessType === 'e-commerce',
          website: this.website,
          hasGdprRegions: this.eu === 'eu',
          channel: this.channel,
          page: this.page
        };
        const createAccount$ = this.apiCallService.createAccount(params, window.location.search);
        const response = await lastValueFrom(createAccount$);
        // console.log('createAccount: ', {response});
        this.winRef.reportGtagEventNew('createNewAccount');
        this.loading = false;
        this.ref.close('verifying');
      }
    }
  }

  cancel(): void {
    this.ref.close();
  }

  removeError(): void {
    this.websiteError = null;
  }


  get disabled(): boolean {
    return !this.accountName?.length ||  !this.selectedCurrency?.length || !this.selectedTimezone?.length ||
      !this.brandNames?.length || !this.website?.length || this.businessType == null || this.eu == null ||
      this.websiteError !== null || this.loading;
  }
}
