<div class="steps" [ngClass]="showMiddleStep ? 'with-middle-text' : ''">
    <div class="step step1" [ngClass]="showMiddleStep ? 'completed' : ''" [attr.isActive]="!accountLinked">
        <div class="num" *ngIf="!accountLinked">1</div>
        <div class="num" *ngIf="accountLinked"></div>
        <div class="text" *ngIf="step1Text">{{step1Text}}<span *ngIf="step1SecText"><br>{{step1SecText}}</span></div>
    </div>
    <div class="line"></div>
    <div class="step middle-text" [ngClass]="isMiddleChaked ? 'completed' : ''" *ngIf="showMiddleStep" [attr.isActive]="!accountLinked || isMiddleChaked">
        <div class="num"></div>
        <div class="text" *ngIf="stepMiddleText">{{stepMiddleText}}</div>
    </div>
    <div class="step step2" [ngClass]="isMiddleChaked ? 'all-checked' : ''" [attr.isActive]="false">
        <div class="num">2</div>
        <div class="text" *ngIf="step2Text">{{step2Text}} <span *ngIf="step2SecText"><br>{{step2SecText}}</span></div>
    </div>
</div>
