<div class="coupon-layout google-coupon yt-layout-addition step2 fixed-header">
    <app-header></app-header>
    <div class="main-content">
        <div><i class="yt-palette main-logo"></i></div>
        <div class="content">
            <span>Get <span class="sign bold">$</span><span class="bold">500</span> YouTube 2024 </span>&nbsp;
            <span>coupon in two easy steps</span>
        </div>
        <div class="google-eligible-btn"><button (click)="openEligibleQ()">For eligible accounts only</button></div>
        <div class="link-acc">
            <app-link-steps [accountLinked]="true" [step1Text]="'Link your Google Ads account'" [step2Text]="'Redeem your $500 coupon'" ></app-link-steps>
            <div class="buttons">
                <div>
                    <p-button styleClass="link-btn" label="Redeem your $500 coupon" (onClick)="RedeemCoupon()" [disabled]="loading">
                        <div></div>
                    </p-button>
                    <div class="loader-box green" *ngIf="loading">
                        <div class="loader">
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="text">Connect your YouTube account to redeem your coupon.</div>
            </div>
        </div>
        <app-metrics [merticData]="metricData"></app-metrics>
        <app-video-player videoPath="https://www.youtube.com/embed/qOAxUEKoVgI"></app-video-player>
        <app-powered [lang]="'en'" [type]="'google'"></app-powered>
        <div *ngIf="!showCsData">
            <app-questions [listItemsData]="csData.questionsAndAnswers" [title]="'Q&A'"></app-questions>
        </div>
        <app-footer [lang]="'en'"></app-footer>
    </div>
</div>
<p-toast key="msgTemplate" position="center">
<ng-template let-message pTemplate="message">
<app-msg-template [message]="message" (confirm)="got_it()">
  <div><p>{{message.data}}</p></div>
</app-msg-template>
</ng-template>
</p-toast>
