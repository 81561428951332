import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IQaA } from '../../helpers/models';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  listItems: Array<any> | undefined;
  listItemsSplit: any | undefined = {
    firstList: [],
    secondList: []
  };
  expandHeight:number = 0;
  @Input() listItemsType: string | undefined;
  @Input() title: string | undefined;
  @Input() isLastStep: boolean = false;
  @Input() listItemsData: any[] | undefined;
  isEn:boolean=false;
  showAllQ:boolean = false;
  selectedAccordinBIndex: number | undefined;
  @Input() selectedAccordinAIndex: number | undefined;
  @Output() changeSelectedTabIndex = new EventEmitter<string>();

  questionsArrays:any = {
    google: [
    { //1
      question:'How can Adcore offer a $500 Google Ads coupon?',
      answerA: "As Adcore is a Google Premier Partner, we are able to offer higher-value coupons for all the accounts that are linked to us. We’d like to use it and help advertisers to benefit more from their Google Ads. Click ",
      linkBetween: 'here',
      link: 'https://www.google.com/partners/agency?id=3987411150',
      answerB: ' to see our certifications. '
    },
    { //2
      id: 'eligibleQ',
      question:'Who is eligible for coupons?',
      listTitle: 'There are several criteria that need to be met for the account to be eligible for coupon:',
      list:[
        'The account has no other promotions applied.',
        'The account is billed to a country where Google Partners promotional offers are offered.',
        'The advertiser is new to Google Ads. A key indicator for Google to determine whether the advertiser is new to Google or not is the landing page url. This process may take up to 35 days.',
        'Account has been created within the last 60 days.'
      ],
      answerA: "A coupon credit of up to $500 (USD or currency equivalent) will be issued to the account upon reaching the required spend value of $500. Read more about Google’s  ",
      linkBetween: 'promotional codes policy',
      link: 'https://support.google.com/adspolicy/answer/1396257?hl=en',
      answerB: ', as terms and conditions may differ between applicants. '
    },
    { //3
      question:'Can I receive multiple coupons?',
      answer: 'Each account is eligible for one coupon only. Therefore, if you have multiple accounts, you can receive a coupon for each account as long as you link them.',
    },
    { //4
      question:'Can I link my MCC instead of a single account?',
      answer: 'Absolutely. As soon as you link a user, all the accounts under it (and future accounts!) will be eligible for coupons.',
    },
    { //5
      question:'What should I do if I receive an error message while trying to link my account?',
      answer: "There are several factors that can cause errors when linking your account. If you encounter an error, please don't hesitate to contact us directly for assistance via chat or email at ",
      link:'support@couponer.app'
    },
    { //6
      question:'Why do Google Ads offer coupons?',
      answer: "Google promo codes are mainly used to encourage new advertisers to try their hands at Google Ads. To see if Google's paid advertising services benefit your business, try them out before spending more money. With our role as a Google Premier Partner, we invite you to start using Google Ads and provide coupons that can help you get started."
    },
    { //7
      question:'Will Google send me an email as part of the process?',
      answer: 'To apply Google promo codes to your accounts, we require access to your Google Ads account. As soon as you finish signing up for Couponer, your account will be automatically linked to our manager account. Google Ads will follow up by sending you an email requesting approval, which you can ignore at your discretion.'
    },
    { //8
      question:'How can I see if a coupon was applied?',
      answerA: "You can check the coupon status on your Google Ads account under Tools and settings → Billing → Promotions. It may take up to 35 days for the coupon to be applied by Google to your account. Read more about Google’s ",
      linkBetween: 'promotional codes policy',
      link: 'https://support.google.com/adspolicy/answer/1396257?hl=en',
      answerB:', as terms and conditions may differ between applicants.'
    },
    { //9
      question:"I successfully linked my account, why can't I see my coupon?",
      answerA: 'Your Google promo code might not appear immediately in your account. Coupon eligibility varies depending on ',
      linkBetween: 'Google’s policy',
      link:'https://support.google.com/adspolicy/answer/1396257?hl=en',
      answerB: 'and may differ between countries.'
    },
    { //10
      question:'How can I be sure Adcore is a Google Premier Partner?',
      answerA: "Adcore is a Premier Google Partner in various geographic locations, including EMEA, APAC, and Australia. To verify Adcore's Google Partner status, you can click this ",
      linkBetween: 'link',
      link: 'https://www.google.com/partners/agency?id=3987411150',
    },
    { //11
      question:'Can I create a new Google Ads account with Couponer?',
      answer: 'Sure! Easily create a Google Ads account and apply for a coupon by clicking "Create new account".',
    },
    { //12
      question:'What safety measures does Couponer implement when creating an account?',
      answer: "Couponer is working to protect its user community by preventing unauthorized access, fraudulent activities, and misuse of the app, ensuring a safer experience for all users.",
    },
    { //13
      question:'What accounts can be linked to Couponer?',
      listTitle: 'To link a single account or MCC to Couponer, the following criteria must be met:',
      list:[
        'Single account’s spend must be at least $1 in the last 30 days.',
        'MCC must have at least one account with spend of at least $1 in the last 30 days.',
        'MCC cannot have more than 200 accounts linked to it.'
      ],
    },
    { //14
      question:'How long does it take to redeem a coupon?',
      answer: "It may take up to 35 days for Google to apply the coupon to your account.",
    },
    { //15
      question:'Does the coupon have an expiration date?',
      answer: 'Google promo codes do not expire once they have been applied. If the $500 spent threshold is not met within 60 days of the account creation, the coupon will no longer be valid.'
    },
    { //16
      question:'Is the coupon valid in different countries?',
      answerA: "There can be differences in the terms and conditions for each promotion based on the country or promotion, so make sure to read Google’s ",
      linkBetween: 'promotional codes policy',
      link: 'https://support.google.com/adspolicy/answer/1396257?hl=en',
      answerB: '.'
    },
    { //17
      question:'What happens if my domain is disapproved?',
      answerA: "You can try to improve your website (use ",
      linkBetween: 'this link ',
      link: 'https://www.adcore.com/blog/optimizing-your-domain-for-success/',
      answerB: ' for help), then make another attempt with the same domain or explore a different domain.'
    },
    { //18
      question:'How will I know if the account was created?',
      answer: 'You will receive an email with the account details.'
    },
    { //19
      question:'How can I unlink my account?',
      answer: 'In order to unlink your account please contact us via chat or email: ',
      link:'support@couponer.app'
    },
    { //20
      question:'Can I share this app with friends?',
      answerA: 'Of course! Just use ',
      linkBetween: 'this link',
      link: 'https://couponer.app/g/?utm_source=referral&utm_medium=share-with-friend&utm_campaign=q-and-a',
    },
    { //21
      question:'How can I contact the support team?',
      answer: 'You can email us to ',
      link:'support@couponer.app'
    }
    ],
    cnGoogle: [
      { //1
        question:'Adcore怎么提供$500的Google Ads优惠券？',
        answerA: "作为 Google 的首席合作伙伴，Adcore 可以为所有与我们链接的帐户提供更高价值的优惠券。我们希望使用这些优惠券来帮助广告商从 Google Ads 中获得更多的利益。 ",
        linkBetween: '点击此处',
        link: 'https://www.google.com/partners/agency?id=3987411150',
        answerB: '查看我们的认证。'
      },
      { //2
        id: 'eligibleQ',
        question:'谁有资格获得优惠券？',
        listTitle: '要使帐户符合优惠券的资格，需要满足以下几个标准：',
        list:[
          '该帐户没有其他促销活动。',
          '该帐户被计费的国家提供Google合作伙伴促销优惠。',
          '广告主是Google Ads的新用户。Google确定广告主是否为Google新用户的一个关键指标是着陆页URL。这个过程可能需要多达35天。',
          '帐户在过去的60天内创建。'
        ],
        answerA: "当达到所需的$500消费值时，将向该帐户发放高达$500（美元或等值货币）的优惠券信用。请阅读有关Google",
        linkBetween: '促销代码政策',
        link: 'https://support.google.com/adspolicy/answer/1396257?hl=en',
        answerB: ',的更多信息，因为申请人之间的条款和条件可能有所不同。'
      },
      { //3
        question:'我可以收到多个优惠券吗？',
        answer: '每个账户只有一个优惠券资格。因此，如果您有多个账户，只要链接它们，每个账户就可以获得一个优惠券。',
      },
      { //4
        question:'我能否将我的MCC链接而不是单个账户？',
        answer: '完全正确。一旦您链接了一个用户，其下的所有账户（以及未来的账户！）都有资格获得优惠券。',
      },
      { //5
        question:'如果我在尝试链接我的账户时收到错误消息，我该怎么办？',
        answerA: "链接账户时可能会出现几个因素导致错误。如果您遇到错误，请不要犹豫通过聊天或发送电子邮件至",
        linkType:true,
        link: 'support@couponer.app',
        linkBetween: 'support@couponer.app',
        answerB: '直接联系我们以获取帮助。'
      },
      { //6
        question:'为什么Google Ads提供优惠券？',
        answer: "Google优惠码主要用于鼓励新广告客户尝试Google Ads。在花费更多资金之前，可以先尝试使用Google的付费广告服务来了解它是否能够为您的业务带来益处。作为Google Premier合作伙伴，我们邀请您开始使用Google Ads，并提供可以帮助您入门的优惠券。"
      },
      { //7
        question:'在此过程中，Google会向我发送电子邮件吗？',
        answer: '要将Google优惠码应用到您的账户中，我们需要访问您的Google Ads账户。一旦您完成Couponer的注册，您的账户将自动链接到我们的管理员账户。Google Ads会随后发送电子邮件给您请求审批，您可以根据自己的意愿选择忽略它。'
      },
      { //8
        question:'我如何查看是否已应用优惠券？',
        answerA: "您可以在Google Ads账户的“工具与设置”→“账单”→“促销”下检查优惠券的状态。Google 最多可能需要 35 天才能将优惠券发放到您的帐户。详细了解 Google 的",
        linkBetween: '折扣码政策',
        link: 'https://support.google.com/adspolicy/answer/1396257?hl=zh-Hans',
        answerB:'，因为条款和条件可能因申请人而异。'
      },
      { //9
        question:"我成功链接了账户，为什么我看不到我的优惠券？",
        answerA: '你的Google优惠码可能不会立即出现在你的账户中。优惠券的资格根据',
        linkBetween: 'Google的政策',
        link:'https://support.google.com/adspolicy/answer/1396257?hl=en',
        answerB: '而异，可能在不同的国家之间有所不同。'
      },
      { //10
        question:'如何确认Adcore是Google Premier合作伙伴？',
        answerA: "Adcore是谷歌的首席合作伙伴（Premier Google Partner），涵盖EMEA，APAC和澳大利亚等多个地理位置。您可以点击此",
        linkBetween: '链接',
        link: 'https://www.google.com/partners/agency?id=3987411150',
        answerB: '查实Adcore的谷歌合作伙伴身份。'
      },
      { //11
        question:'我可以使用 Couponer 创建一个新的 Google Ads 账户吗？',
        answer: '当然！只需点击“创建新账户”轻松创建一个 Google Ads 账户，并申请优惠券。',
      },
      { //12
        question:'Couponer在创建账户时采取了哪些安全措施？',
        answer: "Couponer正在努力保护其用户社区，防止未经授权的访问、欺诈活动和应用程序的滥用，确保所有用户的更安全体验。",
      },
      { //13
        question:'什么样的账户可以链接到Couponer？',
        listTitle: '要将单个账户或MCC链接到Couponer，必须满足以下条件：',
        list:[
          '单个账户在过去的30天内支出至少为$1。',
          'MCC必须至少有一个账户在过去的30天内支出至少为$1。',
          'MCC不能链接超过200个账户。'
        ],
      },
      { //14
        question:'兑换优惠券需要多长时间？',
        answer: "Google可能需要多达35天的时间才能将优惠券应用到您的账户中。",
      },
      { //15
        question:'优惠券有过期日期吗？',
        answer: '一旦应用了Google优惠码，它们就不会过期。如果在账户创建后的60天内未达到500美元的广告支出门槛，则该优惠券将不再有效。'
      },
      { //16
        question:'优惠券在不同的国家有效吗？',
        answerA: "不同国家或促销活动的优惠券使用条款和条件可能会有所不同，因此请务必仔细阅读Google的",
        linkBetween: '促销代码政策',
        link: 'https://support.google.com/adspolicy/answer/1396257?hl=en',
        answerB: '。'
      },
      { //17
        question:'如果我的域名被驳回会发生什么？',
        answerA: "你可以尝试改进你的网站（",
        linkBetween: '使用此链接获取帮助',
        link: 'https://www.adcore.com/blog/optimizing-your-domain-for-success/',
        answerB: '），然后再次尝试使用同一域名或探索其他域名。'
      },
      { //18
        question:'如何确认账户是否已创建？',
        answer: '您将收到一封包含账户详情的电子邮件。'
      },
      { //19
        question:'我如何取消链接我的账户？',
        answerA: '要取消链接您的账户，请通过在线聊天或发送电子邮件至',
        linkType:true,
        link: 'support@couponer.app',
        linkBetween: 'support@couponer.app',
        answerB: '与我们联系。'
      },
      { //20
        question:'我可以与朋友分享这个应用吗？',
        answerA: '当然！只需使用此',
        linkBetween: '链接',
        link: 'https://couponer.app/g-cn/?utm_source=referral&utm_medium=share-with-friend&utm_campaign=q-and-a',
      },
      { //21
        question:'如何联系支持团队？',
        answerA: '您可以发送电子邮件至',
        linkType:true,
        link: 'support@couponer.app',
        linkBetween: 'support@couponer.app',
        answerB: '与我们联系。'
      }
    ],
    microsoft:[
      { //1
        question:'How can Adcore offer a $400 Microsoft Ads coupon?',
        answerA: "As Adcore is a Microsoft Ads Elite Partner, we are able to offer higher-value coupons for all the accounts that are linked to us. We’d like to use it and help advertisers to benefit more from their Microsoft Ads. Click ",
        linkBetween: 'here',
        link: 'https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc',
        answerB: ' to see our certifications.'
      },
      { //2
        id: 'eligibleQ',
        question:'Who is eligible for coupons?',
        listTitle: 'There are several criteria that need to be met for the account to be eligible for coupon:',
        list:[
          'Newly created account (last 30 days)',
          'No more than one promotional offer per MCC',
          'Spend of at least $1',
          'Coupon is valid for 90 days and only after reaching the required spend value of $200'
        ],
        answerA: "A coupon credit of up to $400 (USD or currency equivalent) will be issued to the account upon reaching the required spend value.Coupon availability is subject to monthly allocation set by Microsoft Advertising and may be occasionally limited or unavailable. Read more about Microsoft’s ",
        linkBetween: 'promotional codes policy',
        link: 'https://help.ads.microsoft.com/apex/index/3/en/50829',
        answerB: ', as terms and conditions may differ between applicants.'
      },
      { //3
        question:'Can I receive multiple coupons?',
        answer: 'No, a coupon can only be applied once per MCC.'
      },
      { //4
        question:'Can I link my MCC instead of a single account?',
        answer: 'Absolutely. You can link your MCC and redeem your coupon for any account under it.'
      },
      { //5
        question:'What should I do if I receive an error message while trying to link my account?',
        answer: "There are several factors that can cause errors when linking your account. If you encounter an error, please don't hesitate to contact us directly for assistance via chat or email at ",
        link:'support@couponer.app'
      },
      { //6
        question:'Why do Microsoft Ads offer coupons?',
        answer: 'Coupons are mainly used to encourage new advertisers to try their hands at Microsoft Ads. To see if Microsoft’s paid advertising services benefit your business, try them out before spending more money. With our role as a Microsoft Ads Elite Partner, we invite you to start using Microsoft Ads and provide coupons that can help you get started.'
      },
      { //7
        question:'Why did I receive an email from Microsoft Ads?',
        answer: 'To apply coupons to your account, we need access to your Microsoft Ads account. As soon as you finish signing up for Couponer, your account will be automatically linked to our manager account. Microsoft Ads will send you an email requesting approval, which you can ignore  at your discretion.'
      },
      { //8
        question:'How can I see if a coupon was applied?',
        answer: "You can check the coupon status on your Microsoft Ads account, under Billing & Payments → Payment method → Promotional offer. It may take up to 7 days for the coupon to be applied to your account."
      },
      { //9
        question:"I successfully linked my account, why can't I see my coupon?",
        answer: "Your Microsoft Ads promotional offer might not appear immediately in your account. Coupon eligibility varies depending on Microsoft’s policy and may differ between countries."
      },
      { //10
        question:'How can I be sure Adcore is a Microsoft Elite Partner?',
        answerA: "Adcore is a Microsoft Ads Elite Partner in various geographic locations, including EMEA, APAC, and Australia. To verify Adcore's Microsoft Ads Partner status, you can click ",
        linkBetween: 'here',
        link: 'https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc',
      },
      { //11
        question:'Can I create a new Microsoft Ads account with Couponer?',
        answer: 'Sure! Easily create a Microsoft Ads account and apply for a coupon by clicking "Create new account".',
      },
      { //12
        question:'What safety measures does Couponer implement when creating an account?',
        answer: "Couponer is working to protect its user community by preventing unauthorized access, fraudulent activities, and misuse of the app, ensuring a safer experience for all users.",
      },
      { //13
        question:'What accounts can be linked to Couponer?',
        listTitle: 'To link a single account or MCC to Couponer, the following criteria must be met:',
        list:[
          'Single account’s spend must be at least $1 in the last 30 days.',
          'MCC must have at least one account with spend of at least $1 in the last 30 days.',
          'MCC cannot have more than 200 accounts linked to it.'
        ],
      },
      { //14
        question:'How long does it take to redeem a coupon?',
        answer: "It may take up to 7 days for the promotional offer to be applied to your account.",
      },
      { //15
        question:'Does the coupon have an expiration date?',
        answer: 'Once applied, coupons will expire after 90 days.'
      },
      { //16
        question:'Is the coupon valid in different countries?',
        answerA: 'There can be differences in the terms and conditions for each promotion based on the country or promotion, so make sure to read all the details in the ',
        linkBetween: 'promotional materials.',
        link: 'https://help.ads.microsoft.com/#apex/ads/en/50829/3',
      },
      { //17
        question:'What happens if my domain is disapproved?',
        answerA: "You can try to improve your website (use ",
        linkBetween: 'this link ',
        link: 'https://www.adcore.com/blog/optimizing-your-domain-for-success/',
        answerB: ' for help), then make another attempt with the same domain or explore a different domain.'
      },
      { //18
        question:'How will I know if the account was created?',
        answer: 'You will receive an email with the account details.'
      },
      { //19
        question:'How can I unlink my account?',
        answer: 'In order to unlink your account please contact us via chat or email: ',
        link:'support@couponer.app'
      },
      { //20
        question:'Can I share this app with friends?',
        answerA: 'Of course. Just use ',
        linkBetween: 'this link',
        link: 'http://couponer.app/ms/',
      },
      { //21
        question:'How can I contact the support team?',
        answer: 'You can email us to ',
        link:'support@couponer.app'
      }
    ],
    cnMicrosoft:[
      { //1
        question:'Adcore如何提供400美元的Microsoft Ads优惠券？',
        answerA: "由于Adcore是Microsoft Ads的优秀合作伙伴，我们能够为与我们关联的所有账户提供更高价值的优惠券。我们希望能够使用这些优惠券，帮助广告主从Microsoft Ads中获得更多利益。",
        linkBetween: '点击这里',
        link: 'https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc',
        answerB: '查看我们的认证证书。'
      },
      { //2
        id: 'eligibleQ',
        question:'谁有资格获得优惠券？',
        listTitle: '需要满足几个条件才能使账户有资格获得优惠券：',
        list:[
          '新创建的账户（最近30天）',
          '每个MCC只能享受一次促销优惠。',
          '至少花费1美元',
          '优惠券有效期为90天，且只有在达到200美元的所需消费金额后才能使用。'
        ],
        answerA: "当账户达到所需的消费金额时，将向其发放最高价值为400美元（或等值货币）的优惠券额度。优惠券的可用性取决于由Microsoft广告设定的每月配额，有时可能受到限制或无法使用。请阅读有关",
        linkBetween: 'Microsoft促销代码政策的更多信息',
        link: 'https://help.ads.microsoft.com/apex/index/3/en/50829',
        answerB: '，因为申请人之间的条款和条件可能有所不同。'
      },
      { //3
        question:'您能够接收多个优惠券吗？',
        answer: '不可以，每个MCC（管理客户中心）只能使用一次优惠券。'
      },
      { //4
        question:'我可以将我的MCC（管理客户中心）与优惠券关联起来，而不是单个账户吗？',
        answer: '确实如此。您可以将您的MCC与优惠券关联，并为其中的任何账户兑换优惠券。'
      },
      { //5
        question:'如果在尝试链接账户时收到错误信息，我应该怎么办？',
        answerA: '在链接您的账户时可能会出现多种原因导致错误。如果您遇到错误，请不要犹豫，通过聊天或发送电子邮件至',
        linkType:true,
        link: 'support@couponer.app',
        linkBetween: 'support@couponer.app',
        answerB: '直接联系我们以寻求帮助。'
      },
      { //6
        question:'微软为什么提供优惠券？',
        answer: '优惠券主要用于鼓励新广告商尝试使用微软广告服务。通过尝试之后再进行进一步的投入，可以了解微软的付费广告服务是否对您的业务有益。作为微软广告的优秀合作伙伴，我们邀请您开始使用微软广告，并提供可以帮助您入门的优惠券。'
      },
      { //7
        question:'为什么我收到了微软广告的电子邮件？',
        answer: '为了将优惠券应用于您的账户，我们需要访问您的微软广告账户。一旦您完成Couponer的注册，您的账户将自动链接到我们的管理账户。微软广告会向您发送一封电子邮件请求批准，您可以根据自己的意愿选择是否忽略该邮件。'
      },
      { //8
        question:'我如何查看优惠券是否已应用？',
        answer: "您可以在 Microsoft Ads 账户的“账单与付款”→“付款方式”→“促销优惠”下检查优惠券的状态。优惠券可能需要最多 7 天时间才能应用到您的账户上。"
      },
      { //9
        question:"我成功链接了我的账户，为什么看不到我的优惠券？",
        answer: "您的微软广告促销优惠可能不会立即出现在您的账户中。优惠券的资格条件因微软的政策而异，在不同的国家或地区可能会有所不同。"
      },
      { //10
        question:'您如何确定Adcore是微软的精英合作伙伴？',
        answerA: "Adcore在包括欧洲、中东和非洲地区（EMEA）、亚太地区（APAC）以及澳大利亚在内的多个地理位置都是微软广告的精英合作伙伴。要验证Adcore的微软广告合作伙伴身份，",
        linkBetween: '您可以点击这里',
        link: 'https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc',
        answerB: '。'
      },
      { //11
        question:'我可以使用 Couponer 创建一个新的 Microsoft Ads 账户吗？',
        answer: '当然！只需点击“创建新账户”轻松创建一个 Microsoft Ads 账户，并申请优惠券。',
      },
      { //12
        question:'Couponer在创建账户时采取了哪些安全措施？',
        answer: "Couponer正在努力保护其用户社区，防止未经授权的访问、欺诈活动和应用程序的滥用，确保所有用户的更安全体验。",
      },
      { //13
        question:'什么样的账户可以链接到Couponer？',
        listTitle: '要将单个账户或MCC链接到Couponer，必须满足以下条件：',
        list:[
          '单个账户在过去的30天内支出至少为$1。',
          'MCC必须至少有一个账户在过去的30天内支出至少为$1。',
          'MCC不能链接超过200个账户。'
        ],
      },
      { //14
        question:'兑换优惠券需要多长时间？',
        answer: "优惠活动的应用到您的账户可能需要最多7天的时间。",
      },
      { //15
        question:'优惠券是否有过期日期？',
        answer: '一旦应用，优惠券在90天后会过期。'
      },
      { //16
        question:'该优惠券在不同国家是否有效？',
        answerA: "根据国家或促销活动，每个促销的条款和条件可能会有所不同，因此请务必仔细阅读",
        linkBetween: '促销材料',
        link: 'https://help.ads.microsoft.com/#apex/ads/en/50829/3',
        answerB: '中的所有详细信息。'
      },
      { //17
        question:'如果我的域名被驳回会发生什么？',
        answerA: "你可以尝试改进你的网站（",
        linkBetween: '使用此链接获取帮助',
        link: 'https://www.adcore.com/blog/optimizing-your-domain-for-success/',
        answerB: '），然后再次尝试使用同一域名或探索其他域名。'
      },
      { //18
        question:'如何确认账户是否已创建？',
        answer: '您将收到一封包含账户详情的电子邮件。'
      },
      { //19
        question:'如何取消链接我的账户？',
        answerA: '为了取消链接您的账户，请通过聊天或发送电子邮件至',
        linkType:true,
        link: 'support@couponer.app',
        linkBetween: 'support@couponer.app',
        answerB: '与我们联系。'
      },
      { //20
        question:'我可以将这个应用程序分享给朋友吗？',
        answerA: '当然可以。只需使用此',
        link: 'http://couponer.app/ms/',
        linkBetween: '链接',
        answerB: '。'
      },
      { //21
        question:'您可以如何联系支持团队？',
        answerA: '您可以发送电子邮件至',
        linkType:true,
        link: 'support@couponer.app',
        linkBetween: 'support@couponer.app',
        answerB: '与我们联系。'
      }
    ]
    }

  constructor() {
  }

  ngOnInit(): void {
    if (this.listItemsData) {
      for (var index in this.listItemsData) {
        if (parseInt(index) < 10) {
          this.listItemsSplit.firstList.push(this.listItemsData[parseInt(index)]);
        }
      }
    }
    if (this.listItemsType)
    {
      this.listItems = this.questionsArrays[this.listItemsType];
      if (this.listItemsType === 'google' || this.listItemsType === 'microsoft') {
        this.isEn = true;
      }
      if (this.listItems && this.isLastStep){
        /*
        if (this.listItemsType === 'google') {
          let addQ:any={
            question:"Why can't I see my coupon?",
            answerA: 'Your coupon may not appear immediately in your account. Coupon eligibility varies depending on and may differ between countries.',
            linkBetween: 'Google’s policy',
            link:'https://support.google.com/adspolicy/answer/1396257?hl=en',
            answerB: 'and may differ between countries.',
          };

          this.listItems.unshift(addQ);
        }
        if (this.listItemsType === 'cnGoogle') {
          let addQ:any={
            question:"为何无法看到换领的优惠券？",
            answerA: '您的优惠券可能不会立即出现在您的帐户中。优惠券的使用资格因 ',
            linkBetween: 'Google 政策',
            link:'https://support.google.com/adspolicy/answer/1396257?hl=zh-Hans',
            answerB: '而异，并且可能因国家/地区而异。',
          };

          this.listItems.unshift(addQ);
        }
        */
       /*
        if (this.listItemsType === 'microsoft') {
          let addQ:any={
            question:"Why can't I see my coupon?",
            answer: 'Your Microsoft Ads promotional offer might not appear immediately in your account. Coupon eligibility varies depending on Microsoft’s policy and may differ between countries.'
          };

          this.listItems.unshift(addQ);
        }
        if (this.listItemsType === 'cnMicrosoft') {
          let addQ:any={
            question:"为什么我看不到我的优惠券？",
            answer: '您的 Microsoft 广告促销优惠可能不会立即出现在您的账户中。优惠券的资格取决于 Microsoft 的政策，并且在不同的国家可能会有所不同。'
          };

          this.listItems.unshift(addQ);
        } */
      }
      for (var index in this.listItems) {
        if (parseInt(index) < 10) {
          this.listItemsSplit.firstList.push(this.listItems[parseInt(index)]);
        } else {
          this.listItemsSplit.secondList.push(this.listItems[parseInt(index)]);
        }
      }
      if (this.listItemsSplit && this.listItemsSplit.secondList) {
        this.expandHeight = this.listItemsSplit.secondList.length * 57;
      }
    }
    else this.listItems = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedAccordinAIndex === 1) {
      this.selectedAccordinBIndex = -2;
    }
  }

  handleAccordinAChange(e:any) {
    if (e) {
      this.selectedAccordinAIndex = e.index;
      this.selectedAccordinBIndex = -2;
      this.changeSelectedTabIndex.emit(e);
    }
  }

  handleAccordinBChange(e:any) {
    if (e) {
      this.selectedAccordinBIndex = e.index;
      this.selectedAccordinAIndex = -2;
      e.index = -2;
      this.changeSelectedTabIndex.emit(e);
    }
  }
}
