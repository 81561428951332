<div class="coupon-selection-layout">
    <div class="title"><h2>{{title}}</h2></div>
    <div class="coupons">
        <div class="desktopView">
            <p-carousel [value]="getCouponListByBrand()" [responsiveOptions]="responsiveOptions" [numVisible]="3" [numScroll]="1" [showIndicators]="false" [circular]="false">
                <ng-template pTemplate="item" let-listItem>
                    <app-coupon-box class="inline-block" [listItem]="listItem" (redeemEvent)="openRequestCouponDialog()" [isSelectionMode]="true"></app-coupon-box>
                </ng-template>
            </p-carousel>
        </div>
        <div class="mobileView">
            <p-carousel [value]="getCouponListByBrand()" [responsiveOptions]="responsiveOptions" [numVisible]="1" [numScroll]="1" [circular]="false">
                <ng-template pTemplate="item" let-listItem>
                    <app-coupon-box class="inline-block" [listItem]="listItem" (redeemEvent)="openRequestCouponDialog()" [isSelectionMode]="true"></app-coupon-box>
                </ng-template>
            </p-carousel>
        </div>
    </div>
    <div><p-button styleClass="primary-btn select-coupon" label="Request coupon" (onClick)="openRequestCouponDialog()"></p-button></div>
    <div *ngIf="type !== 'mcard'"><span>New to {{data.couponFullName}}?</span><p-button styleClass="secondary-btn select-coupon" label="Create an account" (onClick)="openRequestCouponDialog()"></p-button></div>
</div>
<p-toast key="msgTemplate" position="center">
  <ng-template let-message pTemplate="message">
    <app-msg-template [message]="message">
      <div><p>{{message.detail}}</p></div>
    </app-msg-template>
  </ng-template>
</p-toast>
