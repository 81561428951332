<div class="powered" [ngClass]="dataItems ? 'home-layout' : ''">
    <div class="powered-position" [ngClass]="!isFirstStep? 'align':''">
        <div class="info" *ngIf="data">
            <div class="title">{{data.title}}</div>
            <div class="text">{{data.text}}</div>
            <div class="buttons" *ngIf="isFirstStep"><p-button styleClass="link-btn" label="{{data.linkBtn}}" (onClick)="linkAcc()"></p-button></div>
        </div>
        <div class="info" *ngIf="dataItems">
            <div class="title">{{dataItems.poweredByHeader}}</div>
            <div class="text">{{dataItems.poweredByContent}}</div>
            <div class="buttons" *ngIf="dataItems"><p-button [styleClass]="type === 'home' ? 'colored-btn link-btn outline': 'link-btn outline'" styleClass="link-btn" label="Select your coupon" (onClick)="goUp()"></p-button></div>
        </div>
        <div class="badget" [ngClass]="type + '-align'">
            <a *ngIf="type && type !== 'home'" [ngClass]="type + '-tag'" href="{{data.tagNavigation}}" target="_blank"><i></i></a>
            <a *ngIf="type === 'microsoft'" [ngClass]="type + '-tag'" href="{{data.tagGlobalNavigation}}" target="_blank"><i></i></a>
            <div class="coupon-tags" [ngClass]="type + '-tag'" *ngIf="dataItems">
                <div>
                    <a class="gl-tag"><i></i></a>
                    <a><i></i></a>
                </div>
                <div>
                    <a><i></i></a>
                    <a><i></i></a>
                </div>
                <div>
                    <a><i></i></a>
                    <a><i></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

