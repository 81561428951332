


  import { Component, Input } from '@angular/core';
  import { Router } from '@angular/router';
  import { MenuItem } from 'primeng/api';
  import { DataServiceService } from '../../helpers/data.service';

  @Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
  })
  export class HeaderComponent {
    @Input() type:string = '';
    isCouponMenuOpen:boolean = false;
    isDropdownOpen:boolean = false;
    showDialog:boolean = false;
    tabList:any = [
      {
        id: '11',
        text:'Homepage',
        label: 'Homepage',
        isTab: true
      },
      {
        id: '12',
        text:'Q&A',
        label: 'Q&A',
        isTab: true
      },
      {
        id: '13',
        text:'Visit Adcore',
        label: 'Visit Adcore',
        isTab: true
      }
    ]
    selectedTabID:string = '';
    menuSelection:MenuItem | undefined = {};
    headerMenuItems: any[] | undefined = [];
    headerMobileMenuItems:any[] = [];

    constructor(public data: DataServiceService, private router: Router) {
      if (this.data) {
        this.headerMenuItems = this.data.headerMenuListItems;
        this.menuSelection = this.data.headerMenuSelectionObj;
        this.couponSelectionTabsArr();
      }
    }

    ngOnInit() {

    }

    openMenu() {
      this.showDialog = true;
    }

    couponSelectionTabsArr() {
      let event:any = {};
      if (this.tabList && this.tabList[0]) {
        let obj = {};
        obj = {
          id: this.tabList[0].id,
          label: this.tabList[0].label,
          isTab : true
        }
        this.headerMobileMenuItems.push(obj);
      }
      if (this.headerMenuItems) {
        for(let i=1;i<this.headerMenuItems.length; i++) {
          let obj = {};
          if (this.headerMenuItems[i] && this.headerMenuItems[i].id) {
            let id = '';
            id = this.headerMenuItems[i].id
            obj = {
              id: this.headerMenuItems[i].id,
              label: this.headerMenuItems[i].label,
              icon: this.headerMenuItems[i].icon,
              styleClass:this.headerMenuItems[i].styleClass,
              urlName: this.headerMenuItems[i].urlName
            }
            this.headerMobileMenuItems.push(obj);
          }

        }
      }
      if (this.tabList) {
        for(let i=1;i<this.tabList.length; i++) {
          let obj = {};
          obj = {
            id: this.tabList[i].id,
            label: this.tabList[i].label,
            isTab : true
          }
          this.headerMobileMenuItems.push(obj);
        }
      }
    }

    changeMobileMenuSelection(item:any) {
      if (item) {
        if (item.isTab) {
          this.tabSelection(item.id)
        } else {
          this.changeMobileSelection(item.id);
        }
      }
    }

    changeMobileSelection(id:string) {
      let event = {
        value : {
          id : id
        }
      }
      this.changeCouponSelection(event);
    }

    changeCouponSelection(event:any) {
      this.showDialog = false;
      if (event && event.value) {
        this.data.goToCoupon(event.value.id);
      }
    }

    tabSelection(id:string) {
      this.showDialog = false;
      this.selectedTabID = id;
      switch (id) {
        case '11':
          this.router.navigate(['/home']);
          break;
        case '12':
          this.goToQA();
          break;
        case '13':
          window.open('https://www.adcore.com', '_blank');
          break;
      }
    }

    isSelectedTabOrCoupon(item:any) {
      if (this.data && this.data.headerMenuSelect && item) {
        if (this.data.headerMenuSelect.id === item.id || (this.data.headerMenuSelect.id === '1' && item.id === '11')) {
          return true
        }
      }
      return false;
    }

    goToQA():void {
      if (document && document.getElementById("qAndA")) {
        let a = document.getElementById("qAndA");
        if (a) {
          a.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }
      }
    }

    getMobileHeaderMenu() {
      let list:MenuItem[] =[];
    }

    onShowCouponMenu(event: any): void {
      setTimeout(() => {
        // solution to fix the issue with selected menu item when open the menu
        this.isCouponMenuOpen = true;
      });
    }

    onHideCouponMenu(event: any): void {
      setTimeout(() => {
        // solution to fix the issue with selected menu item when open the menu
        this.isCouponMenuOpen = false;
      });
    }

  }

