<div class="coupon-layout microsoft-coupon fixed-header">
  <app-header></app-header>
  <div class="main-content">
    <div class="microsoft-image">
      <div class="image">
          <div class="shadow"></div>
          <div class="on-top-shadow">
              <div class="adcore-logo white"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
              <span class="microsoft-tag-box"><a class="microsoft-tag partner" href="https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc" target="_blank"></a></span>
              <span class="microsoft-tag-box"><a class="microsoft-tag global-parthner" href="https://about.ads.microsoft.com/en-us/blog/post/june-2023/2023-microsoft-advertising-global-partner-award-winners#:~:text=Global%20Channel%20Partner,across%20the%20board" target="_blank"></a></span>
              <div class="content">
                  <div>Get $400 Microsoft Ads</div>
                  <div>coupon in two easy steps.</div>
              </div>
              <div class="mic-eligible-btn"><button (click)="openEligibleQ()">For eligible accounts only</button></div>
              <div class="link-acc">
                  <app-link-steps [accountLinked]="true" [step1Text]="'Link your'" [step1SecText]="'Microsoft Ads account'" [step2Text]="'Redeem your'" [step2SecText]="'$500 coupon'" [stepMiddleText]="'Checked'" [showMiddleStep]="true" [accountLinked]="true" [isMiddleChaked]="true"></app-link-steps>
                  <div class="buttons">
                    <div>
                      <div>
                        <p-button styleClass="link-btn linked" label="Redeem your coupons" (onClick)="RedeemCoupon()" [disabled]="loading"></p-button>
                        <div class="loader-box blue" *ngIf="loading">
                          <div class="loader">
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div class="text redeem">Connect your Microsoft Ads account to redeem your coupon.</div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <app-metrics [merticData]="metricData"></app-metrics>
  <app-video-player videoPath="https://www.youtube.com/embed/sLjaUgG69DM" [type]="'microsoft'"></app-video-player>
  <app-powered [lang]="'en'" [type]="'microsoft'"></app-powered>
  <app-questions [listItemsType]="'microsoft'" [title]="'Q&A'" [selectedAccordinAIndex]="openTabIndex" (changeSelectedTabIndex)="changeSelectedTabIndex($event)"></app-questions>
  <app-footer [lang]="'en'"></app-footer>
  </div>
</div>
<p-toast key="msgTemplate" position="center">
  <ng-template let-message pTemplate="message">
    <app-msg-template [message]="message" (confirm)="got_it()">
      <div><p>{{message.detail}}</p></div>
    </app-msg-template>
  </ng-template>
</p-toast>
