<p-progressBar *ngIf="loading && isLoggedIn" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
<p-card *ngIf="!isLoggedIn" [style]="{'margin': '20px', 'padding': '20px'}">
  <div class="p-field p-grid">
    <label for="username" class="p-col-fixed" style="width:200px">Username</label>
    <div class="p-col">
      <input id="username" [(ngModel)]="userName" type="text" pInputText autocomplete="off">
    </div>
  </div>
  <br/>
  <div class="p-field p-grid">
    <label for="password" class="p-col-fixed" style="width:200px">Password</label>
    <div class="p-col">
      <input id="password" [(ngModel)]="userPassword" type="password" autocomplete="off" pInputText>
    </div>
  </div>
  <br/>
  <p-button type="button" label="Login" (onClick)="login()" [disabled]="!userName || !userPassword"></p-button>
</p-card>
<p-card *ngIf="isLoggedIn && !loading" [style]="{'margin': '20px', 'padding': '20px'}">
  <ng-template pTemplate="header">
    <div style="display: inline-flex; margin-left: 20px">
      <div>Configuration:</div>
      <p-dropdown [style]="{'margin-left': '10px'}" [options]="configurations" [(ngModel)]="selectedConfiguration" optionLabel="coupon" placeholder="Select configuration"></p-dropdown>
      <div style="margin-left: 20px">Language:</div>
      <p-dropdown [style]="{'margin-left': '10px'}" *ngIf="selectedConfiguration" [options]="languages" [(ngModel)]="selectedLanguage" placeholder="Select language"></p-dropdown>
    </div>
  </ng-template>
  <table *ngIf="selectedConfiguration" style="padding-top:5px; border-spacing: 20px; border-collapse: separate">
    <tr>
      <td colspan="2"><h1>{{selectedConfiguration.coupon}}</h1><hr></td>
    </tr>
    <ng-container *ngIf="selectedConfiguration.welcome">
      <tr>
        <td colspan="2"><h3>Welcome section</h3><hr></td>
      </tr>
      <tr>
        <td><small><strong>Header</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.welcome.header[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Content</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.welcome.content[selectedLanguage]"></textarea></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="selectedConfiguration.badge1">
      <tr>
        <td colspan="2"><h3>Badge 1</h3><hr></td>
      </tr>
      <tr>
        <td><small><strong>Title</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge1.title[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Description</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge1.description[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Tooltip</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge1.tooltip[selectedLanguage]"></textarea></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="selectedConfiguration.badge2">
      <tr>
        <td colspan="2"><h3>Badge 2</h3><hr></td>
      </tr>
      <tr>
        <td><small><strong>Title</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge2.title[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Description</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge2.description[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Tooltip</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge2.tooltip[selectedLanguage]"></textarea></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="selectedConfiguration.badge3">
      <tr>
        <td colspan="2"><h3>Badge 3</h3><hr></td>
      </tr>
      <tr>
        <td><small><strong>Title</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge3.title[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Description</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge3.description[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Tooltip</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.badge3.tooltip[selectedLanguage]"></textarea></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="selectedConfiguration.poweredBy">
      <tr>
        <td colspan="2"><h3>PoweredBy section</h3><hr></td>
      </tr>
      <tr>
        <td><small><strong>Header</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.poweredBy.header[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Content</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.poweredBy.content[selectedLanguage]"></textarea></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="selectedConfiguration.thankYou">
      <tr>
        <td colspan="2"><h3>ThankYou section</h3><hr></td>
      </tr>
      <tr>
        <td><small><strong>Header</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.thankYou.header[selectedLanguage]"></textarea></td>
      </tr>
      <tr>
        <td><small><strong>Content</strong></small></td>
        <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="selectedConfiguration.thankYou.content[selectedLanguage]"></textarea></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="selectedConfiguration['q&a']">
      <tr>
        <td colspan="2"><h3>Q&A section&nbsp;<p-button type="button" [style]="{'margin-left': '20px'}" label="Add New" (onClick)=" selectedConfiguration['q&a'].push({
          question: {en: ''},
          answer: {en: ''}
        })"></p-button></h3><hr></td>
      </tr>
      <ng-container *ngFor="let qa of selectedConfiguration['q&a']">
        <tr>
          <td><small><strong>Question</strong></small></td>
          <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="qa.question[selectedLanguage]"></textarea></td>
        </tr>
        <tr>
          <td><small><strong>Answer</strong></small></td>
          <td><textarea rows="2" cols="150" pInputTextarea [(ngModel)]="qa.answer[selectedLanguage]"></textarea></td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
  <ng-template pTemplate="footer">
    <p-button type="button" label="Save" (onClick)="saveConfiguration()" size="large" severity="help" [loading]="saving" *ngIf="selectedConfiguration"></p-button>
  </ng-template>
</p-card>
