<div class="coupon-layout home fixed-header">
    <app-header></app-header>
    <div class="main-content">
        <div id="topViewContent">
            <div><h1>{{csData.welcomeHeader}}</h1></div>
            <div><h2>{{csData.welcomeContent}}</h2></div>
            <div class="coupon-layout-home-body">
                <div class="coupon-box-layout">
                    <div class="coupon-box-large-layout">
                        <!--add carusel-->
                        <div class="big-screen-layout" *ngIf="getCaruselListNoPopular()">
                            <div class="popular-c">
                                <app-coupon-box [listItem]="this.data.popularCouponItem" [isSecondaryLayout]="true" (redeemEvent)="goToCoupon()"></app-coupon-box>
                            </div>
                            <div class="carusel-position">
                                <p-carousel [value]="caruselListNoPopular" [responsiveOptions]="responsiveOptions" [numVisible]="1" [numScroll]="1" [showIndicators]="false" [circular]="true" [autoplayInterval]="3000">
                                    <ng-template pTemplate="item" let-listItem>
                                        <app-coupon-box *ngIf="listItem.isPrimary" [isSecondaryLayout]="true" [listItem]="listItem" class="inline-block" (redeemEvent)="goToCoupon()"></app-coupon-box>
                                    </ng-template>
                                </p-carousel>
                            </div>
                        </div>
                        <div class="small-screen-layout" *ngIf="getCaruselList()">
                            <p-carousel [value]="caruselList" [responsiveOptions]="responsiveOptions" [numVisible]="2" [numScroll]="1" [showIndicators]="false" [circular]="true" [autoplayInterval]="3000">
                                <ng-template pTemplate="item" let-listItem>
                                    <app-coupon-box *ngIf="listItem.isPrimary" [isSecondaryLayout]="true" [listItem]="listItem" class="inline-block" (redeemEvent)="goToCoupon()"></app-coupon-box>
                                </ng-template>
                            </p-carousel>
                        </div>
                    </div>
                    <app-all-coupons></app-all-coupons>
                </div>
            </div>
            <div class="powered-colored-layout">
                <app-powered [lang]="'en'" [type]="'home'" [dataItems]="cs"></app-powered>
            </div>
            <div class="works-steps">
                <h2>How it works</h2>
                <div class="flex">
                    <div class="col coupon">
                        <i></i>
                        <div class="title">Choose a coupon</div>
                        <div class="text">Choose a channel you'd like to get a coupon</div>
                    </div>
                    <div class="arrow col-1	"></div>
                    <div class="col acc">
                        <i></i>
                        <div class="title">Connect account</div>
                        <div class="text">Connect or create your ad account</div>
                    </div>
                    <div class="arrow col-1	"></div>
                    <div class="col redeem">
                        <i></i>
                        <div class="title">Redeem</div>
                        <div class="text">Redeem your coupon!</div>
                    </div>
                </div>
            </div>
            <div *ngIf="!showCsData">
                <app-questions [listItemsData]="csData.questionsAndAnswers" [title]="'Q&A'"></app-questions>
            </div>
            <app-footer [lang]="'en'"></app-footer>
        </div>
    </div>
</div>



