<div class="footer" [attr.isEn]="lang === 'en'">
    <div class="top-section" *ngIf="lang === 'en'">
        <div class="contact desk">
            <div class="title"><i></i><span>{{footerLang.contactTitle}}</span></div>
            <div class="content">
                <div class="links">
                    <a class="footer-links" href="https://www.adcore.com/contact/?ref=footer" target="_blank">{{footerLang.contactLinks}}</a>
                    <i class="arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="contact mobile">
            <a href="https://www.adcore.com/contact/?ref=footer" target="_blank"><div class="title"><i></i><span>{{footerLang.contactTitle}}</span></div></a>
        </div>
        <div class="about desk">
            <div class="title"><i></i><span>{{footerLang.aboutTitle}}</span></div>
            <div class="content">
                <div class="links">
                    <a class="footer-links" href="https://www.adcore.com/careers/" target="_blank">{{footerLang.aboutLinks.link1}}</a>,
                    <a class="footer-links" href="https://www.adcore.com/blog/" target="_blank">{{footerLang.aboutLinks.link2}}</a>,
                    <a class="footer-links" href="https://www.adcore.com/investors/" target="_blank">{{footerLang.aboutLinks.link3}}</a>
                    <i class="arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="about mobile">
            <a href="https://www.adcore.com/about-us/" target="_blank"><div class="title"><i></i><span>{{footerLang.aboutTitle}}</span></div></a>
        </div>
        <div class="image">
            <span></span>
        </div>
    </div>
    <div class="middle-section">
        <div *ngIf="lang === 'en'">
            <div class="logo"><i></i></div>
            <div class="follow">
                <div class="title">{{footerLang.followTitle}}</div>
                <div class="icons">
                    <a href="https://www.linkedin.com/company/adcoreinc" target="_blank"><i></i></a>
                    <a href="https://www.facebook.com/AdcoreInc" target="_blank"><i></i></a>
                    <a href="https://twitter.com/adcorelive" target="_blank"><i></i></a>
                    <a href="https://www.instagram.com/adcore.inc/" target="_blank"><i></i></a>
                    <a href="https://www.youtube.com/user/goadcore" target="_blank"><i></i></a>
                    <a href="https://www.pinterest.ca/adcoreinc/?invite_code=9ede30d8a85641589a7a2973cbb0a682&sender=784893178716399815" target="_blank"><i></i></a>
                    <a target="_blank"><i></i></a>
                </div>
            </div>
            <div class="apps">
                <div class="title">{{footerLang.appsTitle}}</div>
                <div class="icons">
                    <a href="https://www.adcore.com/technologies/effortless-marketing/" target="_blank"><i></i></a>
                    <a href="https://www.adcore.com/technologies/feeditor/?ref=techmain" target="_blank"><i></i></a>
                    <a href="https://www.adcore.com/technologies/feeditor-plus-product-feed-optimization/" target="_blank"><i></i></a>
                    <a href="https://www.adcore.com/technologies/semdoc/?ref=techmain" target="_blank"><i></i></a>
                    <a href="https://www.adcore.com/technologies/views/?ref=techmain" target="_blank"><i></i></a>
                    <a href="https://www.adcore.com/technologies/Alerter/?ref=techmain" target="_blank"><i></i></a>
                    <a href="https://www.adcore.com/technologies/media-blast/" target="_blank"><i></i></a>
                </div>
            </div>
            <div class="logo-mobile"><i></i></div>
        </div>
        <div *ngIf="lang === 'cn'" style="height: 100%;">
            <div class="cn-sections" style="height: 100%;">
                <div class="sections-box">
                    <div class="section section1">
                        <div class="title">{{footerLang.section1.title}}</div>
                        <div class="list" *ngFor="let item of footerLang.section1.list">
                            <div><a [href]="item.link" target="_blank">{{item.text}}</a></div>
                        </div>
                    </div>
                    <div class="section section2">
                        <div class="title">{{footerLang.section2.title}}</div>
                        <div class="list" *ngFor="let item of footerLang.section2.list">
                            <div><a [href]="item.link" target="_blank">{{item.text}}</a></div>
                        </div>
                    </div>
                </div>
                <div class="section section3">
                    <div class="title">{{footerLang.section3.title}}</div>
                    <div class="list" *ngFor="let item of footerLang.section3.list">
                        <div><a [href]="item.link" target="_blank">{{item.text}}</a></div>
                    </div>
                </div>
                <div class="section section4">
                    <div class="title">{{footerLang.section4.title}}</div>
                    <div class="list" *ngFor="let item of footerLang.section4.list">
                        <div class="list-row"><i [ngClass]="item.type"></i><a [href]="item.link" target="_blank">{{item.text}}</a></div>
                    </div>
                </div>
                <div class="section section5">
                    <div class="qr"><i></i></div>
                    <div class="icons">
                        <div class="follow">
                            <div class="icons light">
                                <a href="https://www.linkedin.com/company/adcoreinc" target="_blank"><i></i></a>
                                <a href="https://www.facebook.com/AdcoreInc" target="_blank"><i></i></a>
                                <a href="https://twitter.com/adcorelive" target="_blank"><i></i></a>
                                <a href="https://www.instagram.com/adcore.inc/" target="_blank"><i></i></a>
                                <a href="https://www.youtube.com/user/goadcore" target="_blank"><i></i></a>
                                <a href="https://www.pinterest.ca/adcoreinc/?invite_code=9ede30d8a85641589a7a2973cbb0a682&sender=784893178716399815" target="_blank"><i></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="logo"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-section">
        <span>© 2024 Adcore Inc.</span>
        <div class="links">
            <a href="https://www.adcore.com/privacy-policy/" target="_blank">{{footerLang.privacy}}</a>
            <span class="separator">|</span>
            <a href="https://www.adcore.com/terms-of-use/" target="_blank">{{footerLang.terms}}</a>
        </div>
    </div>
</div>



