<div class="coupon-layout google-coupon yt-layout-addition fixed-header">
  <app-header></app-header>
  <div class="main-content">
    <div class="adcore-logo"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
    <a class="youtube-tag" href="" target="_blank"><i></i></a>
    <div class="content">
        <span>Get <span class="sign bold">$</span><span class="bold">500</span> YouTube 2024</span> &nbsp;
        <span>coupon in two easy steps</span>
    </div>
    <div class="google-eligible-btn"><button (click)="openEligibleQ()">For eligible accounts only</button></div>
    <div class="link-acc">
      <app-link-steps [accountLinked]="false" [step1Text]="'Link your YouTube account'" [step2Text]="'Redeem your $500 coupon'"></app-link-steps>
      <div class="buttons">
          <div>
              <div>
                <p-button styleClass="link-btn" label="Link your YouTube Ads account" (onClick)="openRecaptcha('link')"></p-button>
              </div>
              <div class="or">Or</div>
              <div class="container create-btn">
                <div class="clickthrough">Create new account</div>
                <div class="hide"><asl-google-signin-button type='standard' size='medium' logo_alignment="center"></asl-google-signin-button></div>
              </div>
          </div>
      </div>
    </div>
    <app-metrics [merticData]="metricData"></app-metrics>
    <app-video-player videoPath="https://www.youtube.com/embed/qOAxUEKoVgI"></app-video-player>
    <app-powered [lang]="'en'" (linkBtnEvent)="openRecaptcha('link')" [isFirstStep]="true" [type]="'google'"></app-powered>
    <div *ngIf="!showCsData">
      <app-questions [listItemsData]="csData.questionsAndAnswers" [title]="'Q&A'"></app-questions>
  </div>
    <app-footer [lang]="'en'"></app-footer>
  </div>
</div>
<p-dialog [(visible)]="showDialog" styleClass="create-acc-dialog" [modal]="true" [blockScroll]="true">
  <ng-template pTemplate="header">
    {{dialogHeader}}
    <i class="success-icon" *ngIf="dialogHeader === 'Account created successfully'"></i>
  </ng-template>
  <p>{{dialogBody}}</p>
  <ng-template pTemplate="footer">
    <p-button *ngIf="redeemButton" label="Got it" [disabled]="loading" (onClick)="got_it('create')" styleClass="primary-btn"></p-button>
  </ng-template>
</p-dialog>
<p-toast key="msgTemplate" position="center" (onClose)="close($event)">
    <ng-template let-message pTemplate="message">
        <app-msg-template [message]="message" (confirm)="got_it('link')">
          <div *ngIf="message.detail === 'Success'">
            <p>The account is already linked to Couponer's manager account.</p>
            <p><a style="text-decoration: underline; cursor:pointer" (click)="linkAccount()">Link another account</a> or <a style="text-decoration: underline; cursor:pointer" (click)="clearToast()">create new account</a>.</p>
          </div>
          <div *ngIf="message.detail === 'Active'">
            <p>Your Google Ads account is not active, <a href='https://support.google.com/google-ads/answer/6366743?hl=en&ref_topic=6375057' target="_blank" (click)="clearToast()"> fix it </a> or <a style="text-decoration: underline; cursor:pointer" (click)="clearToast()">create a new one</a>.</p>
          </div>
          <div *ngIf="message.detail !== 'Success' && message.detail !== 'Active'"><p>{{message.data}}</p></div>
        </app-msg-template>
    </ng-template>
</p-toast>
