import { Component } from '@angular/core';
import { DataServiceService } from '../helpers/data.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrl: './thank-you.component.scss'
})
export class ThankYouComponent {
  menuSelection:MenuItem | undefined = {};

  constructor(public data:DataServiceService){
    this.menuSelection = this.data.headerMenuSelectionObj;
  }
  

}
