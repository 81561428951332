import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-msg-template',
  templateUrl: './msg-template.component.html',
  styleUrls: ['./msg-template.component.scss']
})
export class MsgTemplateComponent implements OnInit {

  @Input() message: any | undefined;
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
  }

  onConfirm() {
    if (this.message.severity === 'success') {
      this.confirm.emit();
    }
    this.messageService.clear("msgTemplate");
  }
}
