<div class="metrics">
    <div class="item linked-acc">
      <div class="amount">{{merticData.link.amount}}<span class="sign">+</span></div>
      <div class="text">{{merticData.link.text}}</div>
    </div>
    <div class="item">
      <div class="amount">{{merticData.redeemed.amount}}<span class="sign">+</span></div>
      <div class="text">{{merticData.redeemed.text}}</div>
    </div>
    <div class="item">
      <div class="amount">{{merticData.saved.amount}}<span class="sign">+</span></div>
      <div class="text">{{merticData.saved.text}}</div>
    </div>
  </div>
