import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap, Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {WindowRefService} from "./helpers/windowref.service";
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private componentDestroyed: Subject<boolean> = new Subject();
  private pageData: any;
  constructor(private primeNGConfig: PrimeNGConfig, private router: Router, private activatedRoute: ActivatedRoute,
              private metaService: Meta, private titleService: Title,
              private winRef: WindowRefService, @Inject(DOCUMENT) private _document: any) {
    this.router.events.pipe(
      takeUntil(this.componentDestroyed),
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe((data: any)  => {
      this.updateDynamicContent(data).then(() => {
        this.winRef.reportGtagEventNew('page_view', {page_location: `/${data.page}`});
      });
    });
  }

  ngAfterViewInit(): void {
  }

  private async updateDynamicContent(data: any): Promise<void> {
    // console.log("app updateDynamicContent: ", {data});
    await this.updateGTM(data.gtmId);
    this.updateFavicon(data.favicon);
    this.titleService.setTitle(data.title);
    this.metaService.updateTag({name: 'description', content: data.description});

    this.updateFacebookDomainVerification(data.facebookDomainVerification);
  }

  private async updateGTM(gtmId: string): Promise<void> {
    return new Promise((resolve, reject) => {
    // Remove existing GTM scripts and noscript
      this._document?.querySelectorAll('script#gtmScript').forEach((el: any) => el?.remove());

      // Insert GTM script
      const script = document.createElement('script');
      script.onload = () => resolve(); // Resolve the promise when the script is loaded
      script.onerror = (error) => reject(error);
      script.id = 'gtmScript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      this._document?.head?.appendChild(script);

      // Additional GTM configuration script
      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtmId}');
      `;
      this._document?.head?.appendChild(inlineScript);
    });
  }

  private updateFavicon(favicon: string): void {
    this._document?.getElementById('faviconId')?.setAttribute('href', favicon);
  }

  private updateFacebookDomainVerification(content: string): void {
    if (!content) {
      return;
    }
    let metaTag: HTMLMetaElement = document.querySelector("meta[name='facebook-domain-verification']") as HTMLMetaElement;
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.name = 'facebook-domain-verification';
      document.head.appendChild(metaTag);
    }
    metaTag.content = content;
  }

  ngOnInit() {
    this.primeNGConfig.ripple = true;
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

}
