import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  
  footerLang:any = {};

  footerLangOptions:any = {
    en: {
      contactTitle: 'Contact Adcore',
      contactLinks: 'Write, call or send us a message',
      aboutTitle:  'About Adcore',
      aboutLinks:{
        link1:'Careers',
        link2:'blog',
        link3:'investor and corporate information'
      },
      followTitle: 'Follow Us',
      appsTitle: 'Our Apps',
      privacy: 'Privacy Policy',
      terms: 'Terms of Use'
    },
    /*cn: {
      contactTitle: '联络 Adcore',
      contactLinks: '请电邮，致电留言给我们',
      aboutTitle:  '关于Adcore',
      aboutLinks:{
        link1:'招聘',
        link2:'博客',
        link3:'投资者关系和公司介绍'
      },
      followTitle: '关注我们',
      appsTitle: '我们的广告科技',
      privacy: '隐私政策',
      terms: '使用条款'
    }*/
    cn: {
      section1: {
        title: '公司',
        list: [
          {text:'关于我们',link:'https://www.adcore.com/about-us/?ref=footer'},
          {text:'投资者关系',link:'https://www.adcore.com/investors/?ref=footer'},
          {text:'领导者',link:'https://www.adcore.com/about-us/team/?ref=footer'},
          {text:'策略',link:'https://www.adcore.com/blog/?ref=footer'},
          {text:'博客',link:'https://www.adcore.com/partners/?ref=footer'},
          {text:'成为合作伙伴',link:'https://www.adcore.com/about-us/talented-team/?ref=footer'},
          {text:'我们的团队',link:'https://www.adcore.com/write-for-adcores-blog-guest-post-guidelines/?ref=footer'},
          {text:'评价',link:'https://www.adcore.com/review/?ref=footer'},
          {text:'联络我们',link:'https://www.adcore.com/contact/?ref=footer'}
        ]
      },
      section2: {
        title: '科技',
        list: [
          {text:'Effortless Marketing',link:'https://www.adcore.com/technologies/effortless-marketing/?ref=footer'},
          {text:'Views',link:'https://www.adcore.com/technologies/views/?ref=footer'},
          {text:'Semdoc',link:'https://www.adcore.com/technologies/semdoc/?ref=footer'},
          {text:'Feeditor',link:'https://www.adcore.com/technologies/feeditor/?ref=footer'},
          {text:'Alerter',link:'https://www.adcore.com/technologies/alerter/?ref=footer'},
          {text:'价钱',link:'https://www.adcore.com/pricing/?ref=footer'},
          {text:'帮助中心',link:'https://help.adcore.com/?ref=footer'},
        ]
      },
      section3: {
        title: '服务',
        list: [
          {text:'涵盖内容',link:'https://www.adcore.com/adcore-elite-digital-marketing-agency/?ref=footer'},
          {text:'案例',link:'https://www.adcore.com/blog/case-studies/?ref=footer'}
        ]
      },
      section4: {
        title: '联系我们',
        list: [
          {type:'phone',text:'65879055 (HK) / 18621825301 (SH)',link:'https://www.adcore.com/cn/undefined?ref=footer'},
          {type:'wa',text:'65879055',link:'https://wa.me/85293825546?ref=footer'},
          {type:'we',text:'18621825301',link:'weixin://dl/chat?18621825301?ref=footer'},
          {type:'mail',text:'info-hk@adcore.com',link:'mailto:info-hk@adcore.com'}
        ]
      },
      privacy: 'Privacy Policy',
      terms: 'Terms of Use'
    }
  }

  @Input() lang: string | undefined;

  constructor() {
   }

  ngOnInit(): void {
    if (this.lang) this.footerLang = this.footerLangOptions[this.lang];
    else this.footerLang = this.footerLangOptions.en;
  }

}
