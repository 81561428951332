<div class="content">
    <div class="coupon-box-dialog-layout">
        <h1>Request {{data.couponFullName}} Coupon</h1>
        <app-coupon-box [isSecondaryLayout]="true" [listItem]="config.data" class="inline-block"></app-coupon-box>
    </div>
    <div>
        <h2>Your details</h2>
        <form>
            <div class="flex field">
                <input class="col col-6" id="firstName" name="firstName" type="text" pInputText
                       [(ngModel)]="redeemInfo.firstName" placeholder="First Name*" autocomplete="off" [required]="true"/>
                <input  class="col col-6" id="lastName" name="lastName" type="text" pInputText placeholder="Last Name*"
                        [(ngModel)]="redeemInfo.lastName" autocomplete="off" [required]="true"/>
            </div>
            <div class="field">
                <input class="col" id="email" name="email" type="text" pInputText placeholder="Email address*"
                       [(ngModel)]="redeemInfo.email" autocomplete="off" [required]="true"
                       (keyup)="removeError('email')"/>
            </div>
            <div class="flex field" *ngIf="config && config.data && config.data.type">
              <aux-phone class="w-12" id="phone" #phone [modelPhoneNumber]="redeemInfo.phoneNumber" [classStyle]="config.data.type + '-palette'" [required]="true"
                         (phoneChangeEvent)="onPhoneOnChange($event)" [countryCode]="currentCountryCode"></aux-phone>
            </div>
            <div class="field">
                <input class="col" id="url" name="url" type="text" pInputText placeholder="Website URL*"
                       [(ngModel)]="redeemInfo.websiteUrl" autocomplete="off" [required]="true"
                       (keyup)="removeError('website')"/>
            </div>
            <div class="field text-left" *ngIf="config && config.data && config.data.type !== 'mcard'">
                <p-checkbox name="createAcc" [(ngModel)]="redeemInfo.createAccount" [binary]="true" [label]="'I need to create an account'"></p-checkbox>
            </div>

        </form>
    </div>
</div>
<div class="footer">
    <small class="login-error mb-1" id="phone-error" *ngIf="phoneError">{{phoneError}}</small>
    <small class="login-error mb-1" id="email-error" *ngIf="emailError">{{emailError}}</small>
    <small class="login-error mb-1" id="website-error" *ngIf="websiteError">{{websiteError}}</small>
    <p-button  label="Send" styleClass="primary-btn select-coupon" [disabled]="checkDisabled()"
               (onClick)="redeem()"></p-button>
    <p-button  label="Cancel"  styleClass="secondary-btn select-coupon" (onClick)="closeDialog()"></p-button>
</div>
