<p-dialog styleClass="video-dialog" [styleClass]="isResize? 'resize video-dialog':'minimize video-dialog'" header="Title" [(visible)]="showVideo"
          position="bottomright"  [draggable]="!isMobile" [resizable]="false"  [transitionOptions]="isMobile? '0ms':'400ms'">
    <ng-template pTemplate="header">
        <button class="resize-btn inline-block" (click)="resize()"><i [ngClass]="isResize? 'minimize-icon':'maximize-icon'"></i></button>
        <div class="text inline-block">{{title}}</div>
    </ng-template>

    <div class="ad-view" [ngClass]="videoName"></div>
</p-dialog>




