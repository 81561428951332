<div id="videoPlayer" class="video-player" *ngIf="urlSafe" [style.height.px]="getHeight()">
    <div class="aligns">
        <iframe style="border: none"
        height="100%"
        width="100%"
        [src]="urlSafe"
        allow="autoplay=true;"
        allowfullscreen
        style="border:none"  
        title="">
         </iframe>
    </div>
</div>  
