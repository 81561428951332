import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {WindowRefService} from "../../helpers/windowref.service";
import { DataServiceService } from 'src/app/helpers/data.service';
import { ConfigService } from 'src/app/helpers/config.service';

@Component({
  selector: 'app-microsoft-coupon-step3',
  templateUrl: './microsoft-coupon-step3.component.html',
  styleUrls: ['./microsoft-coupon-step3.component.scss']
})
export class MicrosoftCouponStep3Component implements OnInit , OnDestroy {

  showConfetti:boolean = false;
  showVideo:boolean = false;
  componentDestroyed: Subject<boolean> = new Subject();
  action: string = 'link';
  csData:any = {};
  showCsData:boolean = true;

  constructor(private router: Router, private winRef: WindowRefService, private activatedRoute: ActivatedRoute, public data:DataServiceService, public cs: ConfigService) {
    this.data.setHeaderMenuSelection('3');
  }

  ngOnInit(): void {
    this.winRef.reportGtagEventNew('pageViewStep3');
    this.activatedRoute.queryParams.pipe(takeUntil(this.componentDestroyed)).subscribe(params => {
      if (params['action']) {
        this.action =params['action'];
      }
    });
    this.openConfetti();
    document.body.scrollTop = 0;
    this.getCsData();
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  async getCsData(): Promise<void> {
    this.csData = {};
    this.showCsData = true;
    await this.cs.getConfigurations('youtube', 'en');
    this.data.setCsObj(this.cs);
    this.csData = this.cs;
    setTimeout(() => {
        this.showCsData = false;
    }, 100);
  }

  linkAccount(): void {
    this.winRef.reportGtagEventNew('pageViewStep4');
    this.router.navigate(['/ms']);
  }

  openConfetti():void {
    setTimeout(()=>{
      this.showConfetti = true;
   }, 2000);
    setTimeout(()=>{
     this.showVideo = true;
    }, 9000);
  }
}
