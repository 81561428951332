<div class="questions" [attr.isEn]="isEn" id="qAndA">
    <div class="title">{{title}}</div>
	<p-accordion (onOpen)="handleAccordinAChange($event)" [activeIndex]="selectedAccordinAIndex">
		<p-accordionTab *ngFor="let item of listItemsSplit.firstList" [header]="item.question" [id]="item.id">
			<p *ngIf="!item.linkBetween">
				<span [innerHTML]="item.answer"></span>
				<a *ngIf="item.link" href="mailto:{{item.link}}" target="_blank"> {{item.link}}.</a>
			</p>
			<p *ngIf="item.linkBetween && !item.list">
				{{item.answerA}}
				<a *ngIf="item.link && !item.linkType" href="{{item.link}}" target="_blank"> {{item.linkBetween}}</a>
				<a *ngIf="item.link && item.linkType" href="mailto:{{item.link}}" target="_blank">{{item.link}}</a>
				{{item.answerB}}
			</p>
			<p *ngIf="item.list">
				<span class="block">{{item.listTitle}}</span>
				<span class="add-space block">
					<span class="block" *ngFor="let item of item.list">
						- {{item}}
					</span>
					<p class="add-space" *ngIf="item.linkBetween">
						{{item.answerA}}
						<a *ngIf="item.link" href="{{item.link}}" target="_blank"> {{item.linkBetween}}</a>
						{{item.answerB}}
					</p>
				</span>
			</p>
		</p-accordionTab>
	</p-accordion>
	<div class="expanAction">
		<div [ngClass]="showAllQ ? 'open':''" [style.height]="showAllQ ? 'auto':0"> <!--[style.height.px]="showAllQ ? expandHeight:0"-->
			<p-accordion styleClass="splitAcc" (onOpen)="handleAccordinBChange($event)" [activeIndex]="selectedAccordinBIndex">
				<p-accordionTab *ngFor="let item of listItemsSplit.secondList" [header]="item.question">
					<p *ngIf="!item.linkBetween">
						<span [innerHTML]="item.answer"></span>
						<a *ngIf="item.link" href="mailto:{{item.link}}" target="_blank"> {{item.link}}.</a>
					</p>
					<p *ngIf="item.linkBetween && !item.list">
						{{item.answerA}}
						<a *ngIf="item.link && !item.linkType" href="{{item.link}}" target="_blank"> {{item.linkBetween}}</a>
						<a *ngIf="item.link && item.linkType" href="mailto:{{item.link}}" target="_blank">{{item.link}}</a>
						{{item.answerB}}
					</p>
					<p *ngIf="item.list">
						<span class="block">{{item.listTitle}}</span>
						<span class="add-space block">
							<span class="block" *ngFor="let item of item.list">
								- {{item}}
							</span>
							<p class="add-space" *ngIf="item.linkBetween">
								{{item.answerA}}
								<a *ngIf="item.link" href="{{item.link}}" target="_blank"> {{item.linkBetween}}</a>
								{{item.answerB}}
							</p>
						</span>
					</p>
				</p-accordionTab>
			</p-accordion>
		</div>
	</div>
	<div class="expand" *ngIf="listItemsSplit.secondList && listItemsSplit.secondList.length > 0">
		<button (click)="showAllQ=!showAllQ">
			<span *ngIf="!showAllQ">More questions</span>
			<span *ngIf="showAllQ">Show less</span>
			<i [ngClass]="showAllQ ? 'arrow up' : 'arrow'"></i>
		</button>
	</div>
</div>
