<div class="coupon-box-small-layout">
    <div>
        <div class="inline-block" *ngFor="let listItem of createShowList()">
            <app-coupon-box [listItem]="listItem" (redeemEvent)="goToCoupon()"></app-coupon-box>
        </div>
        <div class="listButtons" *ngIf="showArrList && showArrList.length > 0">
            <p-button styleClass="showMoreBtn" *ngIf="couponList.length !== showArrList.length" type="button" label="Load more" (onClick)="showMoreCoupons()"><i></i></p-button>
            <p-button styleClass="showLessBtn" *ngIf="couponList.length === showArrList.length" type="button" label="show less" (onClick)="showLessCoupons()"><i></i></p-button>
        </div>
    </div>
</div>
