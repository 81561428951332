<!-- ****old UI do not delete!****
<div class="coupon-layout microsoft-coupon last-step">
    <div class="show-coupon">
        <div class="microsoft-image">
            <div class="image">
                <div class="shadow"></div>
                <div class="on-top-shadow">
                    <div class="adcore-logo white"><a href="https://www.adcore.com" target="_blank"><i></i></a></div>
                    <div class="microsoft-coupon-image"><div class="confetti-cover" *ngIf="showConfetti"></div></div>
                    <div class="content">
                      <div *ngIf="action === 'link'">
                        <span>Coupon request successfully submitted!</span>
                      </div>
                      <div *ngIf="action === 'create'">
                        <span>Account creation request successfully submitted!</span>
                      </div>
                    </div>
                    <div class="video-mobile-view">
                        <app-ad-video [showVideo]="true" title="Where do I see my coupon?" [isMobile]="true" videoName="microsoft-video"></app-ad-video>
                    </div>
                    <div class="microsoft-ad-text">
                      <div *ngIf="action === 'link'">
                        If your account is eligible, it may take up-to 35 days for the coupon to be sent.
                        For more information on account eligibility and promotional codes policy, please refer to Microsoft Ads’ 
                        <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank"> T&Cs</a> & <button class="qBtn" (click)="goToQA()">Couponer Q&A</button>
                      </div>
                      <div *ngIf="action === 'create'">
                        Thank you for requesting an account with Couponer! If your domain meets the qualifications, we will notify you via email. Upon approval, it may take up to 35 days for Google to apply the coupon. For more information on account eligibility and promotional code policies, please refer to Microsoft Ads’s <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank"> T&Cs</a> & <button class="qBtn" (click)="goToQA()">Couponer Q&A</button>
                      </div>
                    </div>
                    <div class="buttons">
                        <div class="get-coupon-btn"><a href="https://couponer.app/g" target="_blank"><span class="icon-box"><i></i></span><span class="text">Get $500 GoogleAds coupon</span></a></div>
                        <span class="or desktop">Or</span>
                        <div class="another-acc-btn"><p-button styleClass="link-btn" label="Link another Microsoft account" (onClick)="linkAccount()"></p-button></div>
                        <div class="or mobile">Or</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="video-desk-view">
            <app-ad-video [showVideo]="showVideo" title="Where do I see my coupon?" [isMobile]="false" videoName="microsoft-video"></app-ad-video>
        </div>
        <div class="apps-main-title">Try out some of our other apps</div>
        <div class="apps">
            <app-items-app [lang]="'en'" src="msft"></app-items-app>
        </div>
    </div>
    <app-questions [listItemsType]="'microsoft'" [title]="'Q&A'" [isLastStep]="true"></app-questions>
    <app-footer [lang]="'en'"></app-footer>
</div>


-->
<div class="coupon-layout fixed-header">
  <app-header></app-header>
  <div class="main-content">
      <div>
          <div>
            <div class="coupon-layout google-coupon">
              <div class="show-coupon">
                <div><i class="mc-palette main-logo"></i></div>
                <div class="content">
                  <div *ngIf="action === 'link'">
                    <span>Coupon request successfully submitted!</span>
                  </div>
                  <div *ngIf="action === 'create'">
                    <span>Account creation request successfully submitted!</span>
                  </div>
                </div>
                <div class="google-ad-text">
                  <div *ngIf="action === 'link'">
                    If your account is eligible, it may take up-to 35 days for the coupon to be sent.
                    For more information on account eligibility and promotional codes policy, please refer to Microsoft Ads’ 
                    <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank"> T&Cs</a> <!--& <button class="qBtn" (click)="goToQA()">Couponer Q&A</button>-->
                  </div>
                  <div *ngIf="action === 'create'">
                    Thank you for requesting an account with Couponer! If your domain meets the qualifications, we will notify you via email. Upon approval, it may take up to 35 days for Google to apply the coupon. For more information on account eligibility and promotional code policies, please refer to Microsoft Ads’s <a href="https://help.ads.microsoft.com/apex/index/3/en/50829" target="_blank"> T&Cs</a> <!--& <button class="qBtn" (click)="goToQA()">Couponer Q&A</button>-->
                  </div>
                </div>
                <div class="buttons">
                  <div class="get-coupon-btn"><a href="https://couponer.app/g" target="_blank"><span class="icon-box"><i></i></span><span class="text">Get $500 GoogleAds coupon</span></a></div>
                  <span class="or desktop">Or</span>
                  <div class="another-acc-btn"><p-button styleClass="link-btn" label="Link another Microsoft account" (onClick)="linkAccount()"></p-button></div>
                  <div class="or mobile">Or</div>
              </div>
              </div>
            </div>
            <div class="popular-coupon">
              <h3>Explore popular coupons</h3>
              <div class="coupon-box-large-layout">
                <app-coupon-box [listItem]="this.data.popularCouponItem" [isSecondaryLayout]="true" (redeemEvent)="this.data.goToCoupon('3')"></app-coupon-box>
              </div>
            </div>
            <h3 class="explore-title">Explore all coupons</h3>
              <div class="coupon-layout-home-body">
                  <app-all-coupons></app-all-coupons>
              </div>
          </div>
      </div>
      <app-footer [lang]="'en'"></app-footer>
  </div>
</div>

