<p-card [styleClass]="getStyleClass()" (click)="isSelectionMode ? selectCoupon() : redeemFunction()" [attr.isSelected]="isSelectionMode ? data.selectedCoupon === listItem : false">
    <ng-template pTemplate="header">
        <i></i>
    </ng-template>
    <div *ngIf="listItem && listItem.type !== 'mcard' || !isSecondaryLayout && listItem.type === 'mcard'">
        <div class="price">{{listItem.price}}</div>
        <div class="desc" [innerHTML]="listItem.title"></div>
        <div class="desc">{{listItem.description}}</div>
    </div>
    <div class="mcard-layout" *ngIf="isSecondaryLayout && listItem && listItem.type === 'mcard'">
        <div class="top-layout flex">
            <div class="col">
                <div class="text">CREDIT CARD</div>
                <div class="icon"><i></i></div>
            </div>
            <div class="col" style="text-align: right;">
                <i class="logo"></i>
            </div>
        </div>
        <div class="middle-layout">
            <label>{{listItem.price}}</label>
        </div>
        <div class="bottom-layout flex">
            <div class="col">
                <div class="text">ADCORE</div>
                <div><p-button label="Redeem" [link]="true" (onClick)="redeemFunction()"></p-button></div>
            </div>
            <div class="col date-layout">
                <div class="date">
                    <div>VALID/THRU</div>
                    <div>04/29</div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="Redeem" [link]="true" (onClick)="redeemFunction()"></p-button>
         <!--add tooltip-->
        <span class="tooltip" [pTooltip]="listItem.tooltip" tooltipPosition="bottom"></span>
    </ng-template>
</p-card>
