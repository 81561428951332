import {Component, OnInit, Input, forwardRef, Output, EventEmitter, DoCheck} from '@angular/core';
import * as jsonModule from './country-codes.json';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
// @ts-ignore
import * as lpn from 'google-libphonenumber';

const removeDialCode = (phoneNumber: string): string => {
    if (phoneNumber && phoneNumber.length) {
        phoneNumber = phoneNumber.substr(phoneNumber.indexOf(' ') + 1);
    }
    return phoneNumber;
};

export const COUNTRY_CODES = (jsonModule as any).default.map((p: any) => {
    p.label = `${p.code} ${p.dial_code}`;
    const {code, label, dial_code} = p;
    return {code, label, dial_code};
});

export const countryCodeByDialCode = (dialCode: string): any => {
    return COUNTRY_CODES.find((c: any) => c.dial_code === dialCode);
};

export const dialCodeByCountryCode = (countryCode: string): any => {
    return COUNTRY_CODES.find((c: any) => c.code === countryCode);
};

export const getCountryDialCode = (input: string = ''): any => {
    if (!input) {
        input = '';
    }
    const parts = input.split(' ');
    const dialCode = parts.length > 1 ? parts.shift() : '';
    const withoutDialCode = parts.join(' ').trim(); // .replace(/-/g, '');
    return {
        withoutDialCode,
        dialCode,
    };
};

// https://ruimarinho.github.io/google-libphonenumber/

export const phoneNumberValidator = (control: PhoneComponent | undefined) => {
    const error = {validatePhoneNumber: {valid: false}};
    let pn: lpn.PhoneNumber;
    if (control) {
        try {
            pn = lpn.PhoneNumberUtil.getInstance().parse(control.value.toString(), control.countryCode);
        } catch (e) {
            return error;
        }

        if (pn) {
            if (!lpn.PhoneNumberUtil.getInstance().isValidNumberForRegion(pn, control.countryCode)) {
                return error;
            }
        }
    }
    return null;
};

@Component({
    selector: 'aux-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useValue: phoneNumberValidator,
            multi: true,
        }]
})
export class PhoneComponent implements OnInit, DoCheck {
    value = '';
    phoneNumber: string | undefined;
    fullPhoneNumber: any = null;
    @Input() countryCode = 'US';
    @Input() isDisabled: any = null;
    private phoneUtil = lpn.PhoneNumberUtil.getInstance();
    @Input() required = false;
    @Input() maxLength = 15;
    countryCodes: any[] = [];
    @Input() classStyle = '';
    @Input() borderClass = '';
    @Output() phoneChangeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() tooltipContent: string | undefined;
    selectedCountry: any;
    onTouched = () => {
    }
    propagateChange = (_: any) => {
    }

    constructor() {
        this.countryCodes = [...COUNTRY_CODES];
    }

    onCodeModelChange(event: any, el: HTMLElement): void {
        this.countryCode = event?.code || this.countryCode;
        el?.focus();
    }

    ngOnInit(): void {
    }

    private get phoneNumberPlaceHolder(): string {
        try {
            return this.phoneUtil.format(this.phoneUtil.getExampleNumber(this.countryCode), lpn.PhoneNumberFormat.INTERNATIONAL);
        } catch (e: any) {
            return e.toString();
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // setDisabledState(isDisabled: boolean): void {
    //   console.log({isDisabled});
    // }

    writeValue(obj: any): void {
        this.phoneNumber = obj;
    }

    @Input()
    get modelPhoneNumber(): string {
        return this.phoneNumber || '';
    }

    set modelPhoneNumber(val: string) {
        const {withoutDialCode, dialCode} = getCountryDialCode(val);
        this.phoneNumber = withoutDialCode;
        const cc = countryCodeByDialCode(dialCode);
        this.changePhoneNum(cc?.code || '', false);
    }

    get dialCodePlaceHolder(): string {
        // const placeholder = this.getPhoneNumberPlaceHolder(code);
        return removeDialCode(this.phoneNumberPlaceHolder);
    }

    onPhoneNumberChange(): void {
        this.changePhoneNum(this.countryCode || '');
    }

    private changePhoneNum(countryCode: string, emitting: boolean = true): void {
        // console.log('PhoneComponent.changePhoneNum', {countryCode, value: this.value});
        this.value = this.phoneNumber || '';
        let pn: lpn.PhoneNumber | undefined;
        try {
            pn = this.phoneUtil.parse(this.phoneNumber, countryCode);
        } catch (e) {
            // console.log({e});
        }

        if (!this.value) {
            this.fullPhoneNumber = null;
        } else {
            const intlNo = pn ? this.phoneUtil.format(pn, lpn.PhoneNumberFormat.INTERNATIONAL) : '';
            this.fullPhoneNumber = {
                number: this.value,
                internationalNumber: intlNo,
                countryCode,
                nationalNumber: pn ? this.phoneUtil.format(pn, lpn.PhoneNumberFormat.NATIONAL) : '',
            };
        }
        this.propagateChange(this.fullPhoneNumber);
        // tslint:disable-next-line:no-unused-expression
        emitting && this.phoneChangeEvent.emit(this.fullPhoneNumber);
    }

    onInputKeyPress(event: KeyboardEvent): void {
        const allowedChars = /[0-9\+\-\ ]/;
        const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
        const allowedOtherKeys = [
            'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown',
            'Home', 'End', 'Insert', 'Delete', 'Backspace'
        ];

        if (!allowedChars.test(event.key)
            && !(event.ctrlKey && allowedCtrlChars.test(event.key))
            && !(allowedOtherKeys.includes(event.key))) {
            event.preventDefault();
        }
    }

    ngDoCheck(): void {
        this.selectedCountry = this.countryCodes.find((c: any) => c.code === this.countryCode);
    }

}
