import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  canNavigateToStep3Google: boolean = false;
  canNavigateToStep3Microsoft: boolean = false;
  canNavigateToStep3Youtube: boolean = false;
  canNavigateToStep3Netflix: boolean = false;
  canNavigateToStep3Linkedin: boolean = false;
  canNavigateToStep3Yahoo: boolean = false;
  canNavigateToStep3Xandr: boolean = false;

  getNavigateFlagByPage(route: string | null): boolean {
    switch (route) {
      case 'g':
      case 'g-cn':
        return this.canNavigateToStep3Google;
      case 'ms':
      case 'ms-cn':
        return this.canNavigateToStep3Microsoft;
      case 'yt':
        return this.canNavigateToStep3Youtube;
      case 'n':
        return this.canNavigateToStep3Netflix;
      case 'in':
        return this.canNavigateToStep3Linkedin;
      case 'yh':
        return this.canNavigateToStep3Yahoo;
      case 'xndr':
        return this.canNavigateToStep3Xandr;
      default:
        return false;
    }
  }
}
