import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CnGoogleCouponStep1Component} from './cn-google-coupon/cn-google-coupon-step1/cn-google-coupon-step1.component';
import {CnGoogleCouponStep2Component} from './cn-google-coupon/cn-google-coupon-step2/cn-google-coupon-step2.component';
import {CnGoogleCouponStep3Component} from './cn-google-coupon/cn-google-coupon-step3/cn-google-coupon-step3.component';
import {
  CnMicrosoftCouponStep1Component
} from './cn-microsoft-coupon/cn-microsoft-coupon-step1/cn-microsoft-coupon-step1.component';
import {
  CnMicrosoftCouponStep2Component
} from './cn-microsoft-coupon/cn-microsoft-coupon-step2/cn-microsoft-coupon-step2.component';
import {
  CnMicrosoftCouponStep3Component
} from './cn-microsoft-coupon/cn-microsoft-coupon-step3/cn-microsoft-coupon-step3.component';

import {GoogleCouponStep1Component} from './google-coupon/google-coupon-step1/google-coupon-step1.component';
import {GoogleCouponStep2Component} from './google-coupon/google-coupon-step2/google-coupon-step2.component';
import {GoogleCouponStep3Component} from './google-coupon/google-coupon-step3/google-coupon-step3.component';

import {
  MicrosoftCouponStep1Component
} from './microsoft-coupon/microsoft-coupon-step1/microsoft-coupon-step1.component';
import {
  MicrosoftCouponStep2Component
} from './microsoft-coupon/microsoft-coupon-step2/microsoft-coupon-step2.component';
import {
  MicrosoftCouponStep3Component
} from './microsoft-coupon/microsoft-coupon-step3/microsoft-coupon-step3.component';
import {AdminComponent} from "./admin/admin.component";
import {HomeComponent} from './home/home.component';
import {canActivateChannel} from "./helpers/steps.guard";
import { CriteoComponent } from './criteo/criteo.component';
import { DvComponent } from './dv/dv.component';
import { RtbComponent } from './rtb/rtb.component';
import { YandexComponent } from './yandex/yandex.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { MastercardComponent } from './mastercard/mastercard.component';
import { YoutubeCouponStep1Component } from './youtube/youtube-coupon-step1/youtube-coupon-step1.component';
import { YoutubeCouponStep2Component } from './youtube/youtube-coupon-step2/youtube-coupon-step2.component';
import { YoutubeCouponStep3Component } from './youtube/youtube-coupon-step3/youtube-coupon-step3.component';
import { XndrComponent } from './xndr/xndr.component';
import { XandrCouponStep1Component } from './xandr-coupon/xandr-coupon-step1/xandr-coupon-step1.component';
import { XandrCouponStep2Component } from './xandr-coupon/xandr-coupon-step2/xandr-coupon-step2.component';
import { XandrCouponStep3Component } from './xandr-coupon/xandr-coupon-step3/xandr-coupon-step3.component';
import { NetflixCouponStep1Component } from './netflix-coupon/netflix-coupon-step1/netflix-coupon-step1.component';
import { NetflixCouponStep2Component } from './netflix-coupon/netflix-coupon-step2/netflix-coupon-step2.component';
import { NetflixCouponStep3Component } from './netflix-coupon/netflix-coupon-step3/netflix-coupon-step3.component';
import { LinkedinCouponStep1Component } from './linkedin-coupon/linkedin-coupon-step1/linkedin-coupon-step1.component';
import { LinkedinCouponStep2Component } from './linkedin-coupon/linkedin-coupon-step2/linkedin-coupon-step2.component';
import { LinkedinCouponStep3Component } from './linkedin-coupon/linkedin-coupon-step3/linkedin-coupon-step3.component';
import { YahooCouponStep1Component } from './yahoo-coupon/yahoo-coupon-step1/yahoo-coupon-step1.component';
import { YahooCouponStep2Component } from './yahoo-coupon/yahoo-coupon-step2/yahoo-coupon-step2.component';
import { YahooCouponStep3Component } from './yahoo-coupon/yahoo-coupon-step3/yahoo-coupon-step3.component';


const routes: Routes = [
  {
    path: 'g',
    component: GoogleCouponStep1Component,
    pathMatch: 'full',
    data: {
      page: 'google-coupon-step1',
      gtmId: 'GTM-K4PRJKC',
      title: '$500 Google Ads coupon 2024 | Couponer',
      description: 'Connect your Google Ads account in 2 easy steps & get up to $500 in Google Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'g/step2',
    component: GoogleCouponStep2Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'google-coupon-step2',
      gtmId: 'GTM-K4PRJKC',
      title: '$500 Google Ads coupon 2024 | Couponer',
      description: 'Connect your Google Ads account in 2 easy steps & get up to $500 in Google Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y',
    }
  },
  {
    path: 'g/step3',
    component: GoogleCouponStep3Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'google-coupon-step3',
      gtmId: 'GTM-K4PRJKC',
      title: '$500 Google Ads coupon 2024 | Couponer',
      description: 'Connect your Google Ads account in 2 easy steps & get up to $500 in Google Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'ms',
    component: MicrosoftCouponStep1Component,
    pathMatch: 'full',
    data: {
      page: 'microsoft-coupon-step1',
      gtmId: 'GTM-K4PRJKC',
      title: '$400 Microsoft Ads coupon 2024 | Couponer',
      description: 'Connect your MSFT Ads account in 2 easy steps & get up to $400 in MSFT Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ms-favicon.ico',
      facebookDomainVerification: '5bve3dgtsltqmkrp19xdlvwippa0wx'
    }
  },
  {
    path: 'ms/step2',
    component: MicrosoftCouponStep2Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'microsoft-coupon-step2',
      gtmId: 'GTM-K4PRJKC',
      title: '$400 Microsoft Ads coupon 2024 | Couponer',
      description: 'Connect your MSFT Ads account in 2 easy steps & get up to $400 in MSFT Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ms-favicon.ico',
      facebookDomainVerification: '5bve3dgtsltqmkrp19xdlvwippa0wx'
    }
  },
  {
    path: 'ms/step3',
    component: MicrosoftCouponStep3Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'microsoft-coupon-step3',
      gtmId: 'GTM-K4PRJKC',
      title: '$400 Microsoft Ads coupon 2024 | Couponer',
      description: 'Connect your MSFT Ads account in 2 easy steps & get up to $400 in MSFT Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ms-favicon.ico',
      facebookDomainVerification: '5bve3dgtsltqmkrp19xdlvwippa0wx'
    }
  },
  /* cn */
  {
    path: 'g-cn',
    component: CnGoogleCouponStep1Component,
    pathMatch: 'full',
    data: {
      page: 'cn-google-coupon-step1',
      gtmId: 'GTM-WCVJD6F',
      title: '获得最多等值3000港币的Google Ads 优惠券',
      description: '只需 2 个简单的步骤即可连接您的 Google Ads 帐户，并自动获得最多等值3000港币的Google Ads 优惠券，自动应用到您的帐户。',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'g-cn/step2',
    component: CnGoogleCouponStep2Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'cn-google-coupon-step2',
      gtmId: 'GTM-WCVJD6F',
      title: '获得最多等值3000港币的Google Ads 优惠券',
      description: '只需 2 个简单的步骤即可连接您的 Google Ads 帐户，并自动获得最多等值3000港币的Google Ads 优惠券，自动应用到您的帐户。',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'g-cn/step3',
    component: CnGoogleCouponStep3Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'cn-google-coupon-step3',
      gtmId: 'GTM-WCVJD6F',
      title: '获得最多等值3000港币的Google Ads 优惠券',
      description: '只需 2 个简单的步骤即可连接您的 Google Ads 帐户，并自动获得最多等值3000港币的Google Ads 优惠券，自动应用到您的帐户。',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'ms-cn',
    component: CnMicrosoftCouponStep1Component,
    pathMatch: 'full',
    data: {
      page: 'cn-microsoft-coupon-step1',
      gtmId: 'GTM-WCVJD6F',
      title: '$400 Microsoft Ads coupon 2024 | Couponer',
      description: 'Connect your MSFT Ads account in 2 easy steps & get up to $400 in MSFT Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ms-favicon.ico',
      facebookDomainVerification: '5bve3dgtsltqmkrp19xdlvwippa0wx'
    }
  },
  {
    path: 'ms-cn/step2',
    component: CnMicrosoftCouponStep2Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'cn-microsoft-coupon-step2',
      gtmId: 'GTM-WCVJD6F',
      title: '$400 Microsoft Ads coupon 2024 | Couponer',
      description: 'Connect your MSFT Ads account in 2 easy steps & get up to $400 in MSFT Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ms-favicon.ico',
      facebookDomainVerification: '5bve3dgtsltqmkrp19xdlvwippa0wx'
    }
  },
  {
    path: 'ms-cn/step3',
    component: CnMicrosoftCouponStep3Component,
    canActivate: [canActivateChannel],
    pathMatch: 'full',
    data: {
      page: 'cn-microsoft-coupon-step3',
      gtmId: 'GTM-WCVJD6F',
      title: '$400 Microsoft Ads coupon 2024 | Couponer',
      description: 'Connect your MSFT Ads account in 2 easy steps & get up to $400 in MSFT Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ms-favicon.ico',
      facebookDomainVerification: '5bve3dgtsltqmkrp19xdlvwippa0wx'
    }
  },
  {
    path: 'administration',
    component: AdminComponent,
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'cr',
    component: CriteoComponent,
    children: [
      {
        path: 'thank_you',
        component: ThankYouComponent,
      },
    ]
  },
  {
    path: 'rtb',
    component: RtbComponent,
    canActivate: [false], // remove this line to enable the route
    children: [
      {
        path: 'thank_you',
        component: ThankYouComponent,
      },
    ]
  },
  {
    path: 'dv',
    component: DvComponent,
    children: [
      {
        path: 'thank_you',
        component: ThankYouComponent,
      },
    ]
  },
  {
    path: 'y',
    component: YandexComponent,
    children: [
      {
        path: 'thank_you',
        component: ThankYouComponent,
      },
    ]
  },
  {
    path: 'yt',
    component: YoutubeCouponStep1Component,
    pathMatch: 'full',
    data: {
      page: 'youtube-coupon-step1',
      gtmId: 'GTM-K4PRJKC',
      title: '$500 Google Ads coupon 2024 | Couponer',
      description: 'Connect your Google Ads account in 2 easy steps & get up to $500 in Google Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'yt/step2',
    component: YoutubeCouponStep2Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'youtube-coupon-step2',
      gtmId: 'GTM-K4PRJKC',
      title: '$500 Google Ads coupon 2024 | Couponer',
      description: 'Connect your Google Ads account in 2 easy steps & get up to $500 in Google Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'yt/step3',
    component: YoutubeCouponStep3Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel],
    data: {
      page: 'youtube-coupon-step3',
      gtmId: 'GTM-K4PRJKC',
      title: '$500 Google Ads coupon 2024 | Couponer',
      description: 'Connect your Google Ads account in 2 easy steps & get up to $500 in Google Ads coupons automatically applied to your account, just like a premier partner.',
      favicon: 'ga-favicon.ico',
      facebookDomainVerification: 'd8aqzmhght8y83f2uu089lcjyoqk3y'
    }
  },
  {
    path: 'mcard',
    component: MastercardComponent,
    children: [
      {
        path: 'thank_you',
        component: ThankYouComponent,
      },
    ]
  },
  /*{
    path: 'xndr',
    component: XndrComponent,
    children: [
      {
        path: 'thank_you',
        component: ThankYouComponent,
      },
    ]
  },*/
  {
    path: 'xndr',
    component: XandrCouponStep1Component,
    pathMatch: 'full'
  },
  {
    path: 'xndr/step2',
    component: XandrCouponStep2Component,
    pathMatch: 'full'
  },
  {
    path: 'xndr/step3',
    component: XandrCouponStep3Component,
    pathMatch: 'full'
  },
  {
    path: 'n',
    component: NetflixCouponStep1Component,
    pathMatch: 'full'
  },
  {
    path: 'n/step2',
    component: NetflixCouponStep2Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel]
  },
  {
    path: 'n/step3',
    component: NetflixCouponStep3Component,
    pathMatch: 'full',
    canActivate: [canActivateChannel]
  },
  {
    path: 'in',
    component: LinkedinCouponStep1Component,
    pathMatch: 'full'
  },
  {
    path: 'in/step2',
    component: LinkedinCouponStep2Component,
    pathMatch: 'full'
  },
  {
    path: 'in/step3',
    component: LinkedinCouponStep3Component,
    pathMatch: 'full'
  },
  {
    path: 'yh',
    component: YahooCouponStep1Component,
    pathMatch: 'full'
  },
  {
    path: 'yh/step2',
    component: YahooCouponStep2Component,
    pathMatch: 'full'
  },
  {
    path: 'yh/step3',
    component: YahooCouponStep3Component,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
})
export class AppRoutingModule {
}
