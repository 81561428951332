import {Injectable} from '@angular/core';

// tslint:disable-next-line:variable-name
const __window = (): any => {
  // return the global native browser window object
  return window;
};


// tslint:disable-next-line:variable-name
const __document = (): any => {
  // return the global native browser document object
  return document;
};

// tslint:disable-next-line:variable-name
const __geoip2 = (): any => {
  // return the global native  geoip2 object
  // @ts-ignore
  return geoip2;
};

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {

  constructor() {
  }

  get nativeDocument(): any {
    return __document();
  }

  get nativeWindow(): any {
    return __window();
  }

  get href(): string {
    return this.nativeWindow.location.href;
  }

  get nativeGeoIp(): any {
    return __geoip2();
  }

  get host(): string {
    return `${this.nativeWindow.location.protocol}//${this.nativeWindow.location.host}`;
  }

  get queryParams(): URLSearchParams {
    const url: URL = new URL(this.nativeWindow.location);
    // const params: URLSearchParams = url.searchParams;
    return url.searchParams;
  }

  getQueryParams(urlStr: string): URLSearchParams | null {
    if (urlStr) {
      const url: URL = new URL(urlStr);
      return url.searchParams;
    }
    return null;
  }

  get queryString(): string {
    return this.nativeWindow.location.search;
  }


  navigate(url: string, target: string = '_blank'): void {
    if (url && this.nativeDocument) {
      try {
        const link = this.nativeDocument.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('target', target);
        link.click();
      } catch (e) {
        console.error({url, e});
        this.nativeWindow.open(url, target);
      }
    }
  }


  reloadLocation(part?: string, delay: number = 0): void {
    setTimeout(() => {
      this.nativeWindow.location.href = (!part) ? this.host : `${this.host}/${part}`;
    }, delay);
  }

  public reportGtagEventNew(eventName?: string | null,  eventValues?: any | null): void {
    if (this.nativeWindow && this.nativeWindow.gtag && eventName) {
      if (eventValues) {
        this.nativeWindow.gtag('event', eventName, eventValues);
      } else {
        this.nativeWindow.gtag('event', eventName);
      }

    }
  }

}
