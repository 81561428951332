<div class="coupon-layout fixed-header">
    <app-header></app-header>
    <div class="main-content">
        <div id="topViewContent">
            <div><i class="dv-palette main-logo"></i></div>
            <div *ngIf="!data.isCouponLastStep">
                <app-coupon-selection [type]="'dv'" [title]="csData.welcomeHeader"></app-coupon-selection>
                <div class="powered-colored-layout">
                    <app-powered [lang]="'en'" [dataItems]="csData"></app-powered>
                </div>
                <div *ngIf="!showCsData">
                    <app-questions [listItemsData]="csData.questionsAndAnswers" [title]="'Q&A'"></app-questions>
                </div>
            </div>
            <div *ngIf="data.isCouponLastStep">
                <router-outlet></router-outlet>
            </div>
            <app-footer [lang]="'en'"></app-footer>
        </div>
    </div>
</div>
