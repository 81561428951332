import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-powered',
  templateUrl: './powered.component.html',
  styleUrls: ['./powered.component.scss']
})
export class PoweredComponent implements OnInit {
  @Input() lang: string | undefined;
  @Input() isFirstStep: boolean | undefined;
  @Input() type: string | undefined;
  @Input() dataItems: any | undefined;
  @Output() linkBtnEvent = new EventEmitter<string>();
  generalData: any = {
    google: {
      en : {
        title: 'Powered by Adcore, a Premier Google Ads Partner',
        text: 'We collaborated with Google and Microsoft to create Couponer, a powerful tool that shortcuts the process of applying for ad credit coupons, advertisers are entitled for, helping them maximize their business potential.',
        linkBtn: 'Link your Google Ads account',
        tagNavigation: 'https://www.google.com/partners/agency?id=3987411150'
      },
      cn : {
        title: '由Adcore提供支持，是Google Ads的首选合作伙伴',
        text: '我们与Google和微软合作，共同开发了Couponer，这是一个强大的工具，可以简化广告信用优惠券申请的流程，帮助广告客户最大化其业务潜力。',
        linkBtn: '关联您的 Google Ads 帐户',
        tagNavigation: 'https://www.google.com/partners/agency?id=3987411150'
      }
    },
    microsoft: {
      en : {
        title: 'Powered by Adcore, a Microsoft Ads Elite Partner',
        text: 'We collaborated with Microsoft and Google to create Couponer, a powerful tool that shortcuts the process of applying for ad credit coupons advertisers are entitled for, helping them maximize their business potential.',
        linkBtn: 'Link your Microsoft Ads account',
        tagNavigation: 'https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc',
        tagGlobalNavigation:'https://about.ads.microsoft.com/en-us/blog/post/june-2023/2023-microsoft-advertising-global-partner-award-winners#:~:text=Global%20Channel%20Partner,across%20the%20board'
      },
      cn : {
        title: '由Adcore提供支持，是Microsoft Ads的首选合作伙伴',
        text: '我们与Google和微软合作，共同开发了Couponer，这是一个强大的工具，可以简化广告信用优惠券申请的流程，帮助广告客户最大化其业务潜力。',
        linkBtn: '关联您的 Microsoft Ads帐户',
        tagNavigation: 'https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc',
        tagGlobalNavigation:'https://about.ads.microsoft.com/en-us/blog/post/june-2023/2023-microsoft-advertising-global-partner-award-winners#:~:text=Global%20Channel%20Partner,across%20the%20board'
      }
    },
  }
  data:any|undefined;


  constructor() {
  }

  ngOnInit(): void {
    if (this.generalData && this.lang && this.type && this.type !== 'home' && this.generalData[this.type]) {
      let data = this.generalData[this.type];
      this.data = data[this.lang];
    }
  }

  linkAcc() {
    this.linkBtnEvent.emit();
  }

  goUp() {
    if (document) {
        setTimeout(function () {
          let a = document.getElementById("topViewContent");
          if (a) {
            a.scrollIntoView({
              behavior: "smooth",
              block: "start",
          });
          }
          }, 100);
      }
    }
}
