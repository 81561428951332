import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-steps',
  templateUrl: './link-steps.component.html',
  styleUrls: ['./link-steps.component.scss']
})
export class LinkStepsComponent implements OnInit {
  
  @Input() accountLinked: boolean | undefined;
  @Input() step1Text: string | undefined;
  @Input() step2Text: string | undefined;
  @Input() step1SecText: string | undefined;
  @Input() step2SecText: string | undefined;
  @Input() stepMiddleText:string | undefined;
  @Input() showMiddleStep:boolean = false;
  @Input() isMiddleChaked:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
