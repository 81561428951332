import {EventEmitter, Injectable} from '@angular/core';
import {ApiCallService} from "./api-call.service";
import {lastValueFrom} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  loading = false;
  currentLanguage = 'en';
  private configuration: any = {};
  private allBadges: any[] = [];
  reportGetConfigDone: EventEmitter<void> = new EventEmitter<void>();

  constructor(private apiCallService: ApiCallService) {
  }


  async getConfigurations(coupon: string, language: string = 'en'): Promise<any> {
    if (!coupon) {
      throw new Error('Coupon is required');
    }
    this.currentLanguage = language;
    this.loading = true;
    const data$ = this.apiCallService.getConfigurations('public', coupon);
    const {configurations, allBadges} = await lastValueFrom(data$);
    this.configuration = (configurations && configurations.length) ? configurations[0] : {};
    this.allBadges = (allBadges || []).reduce((badges: any[], b: any) => {
      const b1 = b.badge1 || {};
      const b2 = b.badge2 || {};
      const b3 = b.badge3 || {};
      b1.coupon = b.coupon;
      b2.coupon = b.coupon;
      b3.coupon = b.coupon;
      badges.push(b1);
      badges.push(b2);
      badges.push(b3);
      return badges;
    }, []);
    this.loading = false;
    this.reportGetConfigDone.emit();
  }

  private get welcome(): any {
    // if (this.loading) {
    //   throw new Error('Configurations are not loaded yet');
    // }
    return this.configuration.welcome || {};
  }

  private get poweredBy(): any {
    // if (this.loading) {
    //   throw new Error('Configurations are not loaded yet');
    // }
    return this.configuration.poweredBy || {};
  }

  private get thankYou(): any {
    // if (this.loading) {
    //   throw new Error('Configurations are not loaded yet');
    // }
    return this.configuration.thankYou || {};
  }

  private get qna(): any[] {
    // if (this.loading) {
    //   throw new Error('Configurations are not loaded yet');
    // }
    return this.configuration['q&a'] || [];
  }

  get welcomeHeader(): string {
    return this.translate(this.welcome.header);
  }

  get welcomeContent(): string {
    return this.translate(this.welcome.content);
  }

  get poweredByHeader(): string {
    return this.translate(this.poweredBy.header);
  }

  get poweredByContent(): string {
    return this.translate(this.poweredBy.content);
  }

  get thankYouHeader(): string {
    return this.translate(this.thankYou.header);
  }

  get thankYouContent(): string {
    return this.translate(this.thankYou.content);
  }

  private get b1(): any {
    return this.configuration.badge1 || {};
  }

  private get b2(): any {
    return this.configuration.badge2 || {};
  }

  private get b3(): any {
    return this.configuration.badge3 || {};
  }

  get questionsAndAnswers(): any[] {
    return this.qna.map((qa) => {
      return {
        question: this.translate(qa.question),
        answer: this.translate(qa.answer)
      };
    });
  }

  get badge1(): any {
    return {
      title: this.translate(this.b1.title),
      description: this.translate(this.b1.description),
      tooltip: this.translate(this.b1.tooltip)
    };

  }

  get badge1Title(): string {
    return this.badge1.title;
  }

  get badge1Description(): string {
    return this.badge1.description;
  }

  get badge1Tooltip(): string {
    return this.badge1.tooltip;
  }

  get badge3(): any {
    return {
      title: this.translate(this.b3.title),
      description: this.translate(this.b3.description),
      tooltip: this.translate(this.b3.tooltip)
    };

  }

  get badge3Title(): string {
    return this.badge3.title;
  }

  get badge3Description(): string {
    return this.badge3.description;
  }

  get badge3Tooltip(): string {
    return this.badge3.tooltip;
  }
  get badge2(): any {
    return {
      title: this.translate(this.b2.title),
      description: this.translate(this.b2.description),
      tooltip: this.translate(this.b2.tooltip)
    };
  }

  get badge2Title(): string {
    return this.badge2.title;
  }

  get badge2Description(): string {
    return this.badge2.description;
  }
  get badge2Tooltip(): string {
    return this.badge2.tooltip;
  }
  private translate(prop: any): string {
    if (!prop) {
      prop = {};
    }
    return prop[this.currentLanguage] || prop.en || 'empty';
  }

  get badges(): any[] {
    return this.allBadges.map((badge) => {
      return {
        coupon:badge.coupon,
        title: this.translate(badge.title),
        description: this.translate(badge.description),
        tooltip: this.translate(badge.tooltip)
      };
    });
  }
}
