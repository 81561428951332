
<div>
    <div class="logo"><i></i></div>
    <div *ngIf="data && data.selectedCouponCsObj">
        <h1>{{data.selectedCouponCsObj.thankYouHeader}}<span>!</span></h1>
        <h2>{{data.selectedCouponCsObj.thankYouContent}}</h2>
    </div>
    <div class="popular-coupon">
        <h3>Explore popular coupons</h3>
        <div class="coupon-box-large-layout">
            <app-coupon-box [listItem]="this.data.popularCouponItem" [isSecondaryLayout]="true" (redeemEvent)="this.data.goToCoupon('3')"></app-coupon-box>
        </div>
    </div>
    <h3>Explore all coupons</h3>
    <div class="coupon-layout-home-body">
        <app-all-coupons></app-all-coupons>
    </div>
</div>

