import {Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../../../environments/environment';
import {takeUntil, Subject, lastValueFrom} from "rxjs";
import {MessageService} from 'primeng/api';
import {WindowRefService} from "../../helpers/windowref.service";
import {DialogService} from 'primeng/dynamicdialog';
import {CreateGoogleAdsAccountComponent} from "../../shared/dialogs/create-google-ads-account/create-google-ads-account.component";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { SocialUser } from "@abacritt/angularx-social-login";
import translation from './messageTranslation.json'
import {ApiCallService} from "../../helpers/api-call.service";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NavigationService } from '../../helpers/navigation.service'
import { DataServiceService } from 'src/app/helpers/data.service';

@Component({
  selector: 'app-cn-google-coupon-step1',
  templateUrl: './cn-google-coupon-step1.component.html',
  styleUrls: ['./cn-google-coupon-step1.component.scss']
})
export class CnGoogleCouponStep1Component implements OnInit, OnDestroy{
  accountLinked:boolean = false;
  openTabIndex:any = undefined;
  customerId: string | null = null;
  showDialog: boolean = false;
  dialogHeader: string = '';
  dialogBody: string = 'An invitation was sent to your mailbox.';
  redeemButton: boolean = true;
  user: SocialUser | undefined;
  loading: boolean = false;
  geo: string = 'cn';
  score: number | undefined;
  receiveMsgFlag = false;
  metricData:any = {
    link: {
      amount: '3,000',
      text: '帐户已链接',
    },
    redeemed: {
      amount: '2,250',
      text: '优惠券已兑换',
    },
    saved: {
      amount: '$955,000',
      text: '已节省',
    }
  };
  page = 'google';
  componentDestroyed: Subject<boolean> = new Subject();
  private handler: any;
  constructor(private router: Router, private authService: SocialAuthService,
              private messageService: MessageService, public dialogService: DialogService,
              private recaptchaV3Service: ReCaptchaV3Service, private winRef: WindowRefService,
              private apiCallService: ApiCallService, private navigationService: NavigationService, public data:DataServiceService) {
                this.data.setHeaderMenuSelection('2');
               }

  ngOnInit(): void {
    this.init();
    this.handler = this.receiveMessage.bind(this);
    this.authService.authState.pipe(takeUntil(this.componentDestroyed)).subscribe((user) => {
      this.user = user;
      if (user) {
        this.checkUserSession();
      }
    });
    document.body.scrollTop = 0;
    this.startPolling()
  }

  openEligibleQ() {
    if (document && document.getElementById("eligibleQ")) {
      let a = document.getElementById("eligibleQ");
      if (a) {
        a.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
      this.openTabIndex = 1;
    }
  }
  changeSelectedTabIndex(e:any) {
    if (e) {
      this.openTabIndex =e.index;
    }
  }

  async checkUserSession() {
    // If there email already have ongoing creation process in db
    this.loading = true;
    const params = {
      user: this.user,
      page: this.page,
      sessionToken: sessionStorage.getItem('googleToken')
    };
    const checkUserSession$ = this.apiCallService.checkUserSession(params, 'google');
    const response = await lastValueFrom(checkUserSession$);
    if (response.result && response.result === 'ok') {
      this.openRecaptcha('create');
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "您已达到创建账户的上限",
        data: "您已达到创建账户的上限",
        closable: true,
        sticky: true
      });
    }
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.user) {
      this.authService.signOut();
    }
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
  }

  get platformURL(): string {
    return `${environment.apiUrl}/v1/google_platform/login`
  }

  async init(): Promise<void> {
    const init$ = this.apiCallService.init('google');
    const response = await lastValueFrom(init$);
    if (response.token) {
      sessionStorage.setItem('googleToken', response.token);
    }
  }

  createAccount(): void {
    const ref = this.dialogService.open(CreateGoogleAdsAccountComponent, {
      header: 'Create Account',
      data: {user: this.user, geo: this.geo, channel: 'google', recaptchaScore: this.score, page: 'google'},
      styleClass: 'create-acc-dialog create-acc-form-dialog',
      modal: true
    });
    ref.onClose.pipe(takeUntil(this.componentDestroyed)).subscribe((returnData: string) => {
      if (returnData === 'verifying') {
        this.messageService.clear();
        this.showDialog = true;
        this.dialogHeader = 'CREAT ACCOUNT';
        this.dialogBody = 'We are verifying your business\'s eligibility for advertising. You will receive an email ' +
          'notification once the verification process is complete.\n';
        this.redeemButton = true;
      }
    });
  }

  cancel(): void {
    this.user = undefined;
    this.customerId = null;
    this.showDialog = false;
  }

  async openRecaptcha(op: string): Promise<void> {
    const ipValidate$ = this.apiCallService.ipValidate('google');
    const response = await lastValueFrom(ipValidate$);
    if (response.enabled) {
      this.recaptchaV3Service.execute(op).pipe(takeUntil(this.componentDestroyed)).subscribe((token: any) =>{
        if (token) {
          const params = {
            token: token,
            sessionToken : sessionStorage.getItem('googleToken'),
            action: op
          };
          this.getRecaptcha(params, op);
        }
      });
    }
    else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "您已经达到每日关联或创建账户的上限，请尝试其他平台或明天再试， 谢谢！",
        data: "您已经达到每日关联或创建账户的上限，请尝试其他平台或明天再试， 谢谢！",
        closable: true,
        sticky: true
      });
    }
  }

  async getRecaptcha(params: any, op: string) {
    const recaptchaAssessment = this.apiCallService.recaptchaAssessment(params);
    const recaptcha_response = await lastValueFrom(recaptchaAssessment);
    this.score = recaptcha_response.score;
    if (this.score && this.score >= 0.8) {
      if (op === 'link') {
        this.linkAccount();
      }
      if (op === "create") {
        this.createAccount();
      }
    } else {
      this.messageService.add({
        key: 'msgTemplate',
        severity: 'warn',
        summary: '',
        detail: "未通过reCAPTCHA验证，请联系我们。",
        data: "未通过reCAPTCHA验证，请联系我们。",
        closable: true,
        sticky: true
      });
    }
  }

  linkAccount(): void {
    this.loading = true;
    this.messageService.clear();
    this.winRef.nativeWindow.removeEventListener('message', this.handler, false);
    this.winRef.reportGtagEventNew('clickOnLinkAccount');
    const params = this.winRef.queryString;
    const suffixUrl = `geo=${this.geo}&score=${this.score}&page=${this.page}`;
    const loginUrl = params.length ? this.platformURL + '/' + params + `&${suffixUrl}` : this.platformURL + `/?${suffixUrl}`;
    const wndParams = `scrollbars=yes,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,top=50,left=200`;
    const newWindow = this.winRef.nativeWindow.open(loginUrl, 'Authenticate', wndParams);

    // Polling to check if the window has been closed
    const checkWindowClosedInterval = setInterval(() => {
      if (newWindow && newWindow.closed) {
        clearInterval(checkWindowClosedInterval);
        if (!this.receiveMsgFlag) {
          this.loading = false;
          // Perform any additional cleanup or actions needed when window is closed
          console.log('Authentication window closed by user.');
          // Optionally, remove the event listener if it's no longer needed
          this.winRef.nativeWindow.removeEventListener('message', this.handler);
        }
        this.receiveMsgFlag = false;
      }
    }, 1000); // Check every second

    this.winRef.nativeWindow.addEventListener('message', this.handler);
  }

  receiveMessage(event: any): any {
    // console.log('ga receiveMessage:', {event});
    this.messageService.clear();
    if (event.origin === `${environment.apiUrl}` && event.data && event.data.refresh_token) {
      this.receiveMsgFlag = true;
      this.winRef.nativeWindow.removeEventListener('message', this.handler, false);
      let error = event.data.error;
      if (event.data.taskId) {
        this.apiCallService.googleLinkTaskId = event.data.taskId;
        sessionStorage.setItem('googleInfo', JSON.stringify(event.data));
        const params = {
          ...event.data,
          sessionToken : sessionStorage.getItem('googleToken'),
          geo: this.geo,
          page: this.page
        }
        this.apiCallService.startSafeguardTask(params, 'google');
        this.startPolling();
      } else {
        this.loading = false;
        this.messageService.add({
          key: 'msgTemplate',
          severity: error,
          summary: '',
          detail: error,
          data:error,
          closable: true,
          sticky: true
        });
      }
    }
  }

  private startPolling() {
    if (this.apiCallService.googleLinkTaskId && this.apiCallService.googleLinkTaskId.length) {
      const taskId = this.apiCallService.googleLinkTaskId;
      this.loading = true;
      let timeout = true;
      this.apiCallService.statusPoll('google').pipe()
        .subscribe(
          {
            next: (res) => {
              timeout = false;
              if (res.status === 'completed') {
                sessionStorage.setItem('googlePassedList', JSON.stringify(res.passedList));
                this.router.navigate(['/g-cn/step2']);
              } else {
                if (res.status === 'timeout') {
                  this.addTimeoutMsg()
                } else {
                  let error = res.error;
                  let severity = res.severity;
                  let summary = '我们为造成的不便道歉。';
                  if (severity === 'success') {
                    summary = '好消息!';
                  }
                  if (severity === 'warn') {
                    summary = '';
                  }
                  this.messageService.add({
                    key: 'msgTemplate',
                    severity: severity,
                    summary: summary,
                    detail: error,
                    data: translation[error as keyof typeof translation],
                    closable: true,
                    sticky: true
                  });
                }

              }
            },
            error: (reason) => {
              console.error({reason});
            },
            complete: () => {
              this.loading = false;
              this.apiCallService.deleteGoogleLinkTaskId();
              if (timeout) {
                this.addTimeoutMsg();
                this.apiCallService.timeoutTask(taskId, sessionStorage.getItem('googleToken')).subscribe();
              }
            }
          }
        );
    }
  }

  addTimeoutMsg() {
    this.messageService.add({
      key: 'msgTemplate',
      severity: 'error',
      summary: '',
      detail: 'timeout',
      data: translation['timeout' as keyof typeof translation],
      closable: true,
      sticky: true
    });
  }

  clearToast(): void {
    this.messageService.clear();
  }
  got_it(action: string): void {
    this.navigationService.canNavigateToStep3Google = true;
    this.router.navigate(['/g-cn/step3'], { queryParams: { action: action } });
  }
  close(event: any):void {
    if (event.message.severity === 'success') {
      this.got_it('link');
    }
  }
}
